import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import styles from "./UserManagement.module.scss";
import Navbar from "../../Components/Navbar";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import { fetchUsers, deleteUserById, fetchSubscriptionForUser } from "../APICalls/user";
import { useQuery } from "@tanstack/react-query";

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  speciality?: string;
  company_name?: string;
  type?: string; 
  subscriptionStatus?: string;
}

function UserList() {
  const navigate = useNavigate();
  const [cookie] = useCookies(["tokens"]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const token = cookie.tokens?.access?.token;

  const { data: users = [], isLoading } = useQuery<User[]>({
    queryKey: ["users", token],
    queryFn: async () => {
      if (!token) throw new Error("Please authenticate");
      const response = await fetchUsers(token);
      return response;
    },
    enabled: !!token, 
  });

  const { data: usersWithSubscriptions } = useQuery<User[]>({
    queryKey: ["usersWithSubscriptions", users, token],
    queryFn: async () => {
      if (!token) throw new Error("Please authenticate");
      const updatedUsers = await Promise.all(
        users.map(async (user) => {
          const response = await fetchSubscriptionForUser(user.id, token);
          const subscription = response.length > 0 ? response[0] : null;
  
          if (subscription && subscription.subscriptionId) {
            user.subscriptionStatus = subscription.subscriptionId.active ? "paid" : "free";
            user.type = subscription.subscriptionId.name;
          }
          return user;
        })
      );
      return updatedUsers;
    },
    enabled: users.length > 0 && !!token,
  });

  const filteredUsers = usersWithSubscriptions?.filter((user) =>
    Object.values(user)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  ) || [];


  const handleDelete = async (id: number) => {
    try {
      if (!token) throw new Error("Please authenticate");
      await deleteUserById(id, token);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleEdit = (id: number) => {
    navigate(`/user/edit/${id}`);
  };

  return (
    <div>
      <Navbar />
      <div className={`${styles.containerWrapper}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.searchbar}`}>
            <Input
              value={searchTerm}
              onChange={(value: string) => setSearchTerm(value)}
            />
            <Button block type="primary" onClick={() => {}}>
              SEARCH
            </Button>
          </div>
          {isLoading && <p>Loading...</p>}
       
          {filteredUsers.length > 0 && (
            <table className={`${styles.reportTable}`}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Specialty</th>
                  <th>Company Name</th>
                  <th>Type</th>
                  <th>Subscription Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.first_name} {user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.speciality}</td>
                    <td>{user.company_name}</td>
                    <td>{user.type || "Basic"}</td> 
                    <td>  
                      <span
                        className={`${styles.subscriptionStatus} ${
                          user.subscriptionStatus === "paid" ? styles.paid : styles.free
                        }`}
                      >
                        {user.subscriptionStatus === "paid" ? "paid" : "Free"}
                      </span></td>
                    <td>
                      <RiEdit2Fill
                        size={20}
                        color="green"
                        onClick={() => handleEdit(user.id)}
                      />
                      <RiDeleteBin2Fill
                        size={20}
                        color="red"
                        onClick={() => handleDelete(user.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserList;
