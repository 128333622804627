// @ts-nocheck
// import CategoryScoreCard from "../CategoryScoreCard";
// import FontPreview from "../FontPreview";
// import Screenshots from "../Screenshots";
// import ColorsRecap from "../ColorsRecap";
// import ColorsDetails from "../ColorsDetails";
import Divider from "../Divider";
import styles from "./reportContent.module.scss";
import {
  // RiAlertLine,
  // RiCloseCircleFill,
  // RiLink,
  RiRestartLine,
} from "@remixicon/react";
import Button from "../Button";
// import Markdown from "react-markdown";
// import UrlDiff from "../UrlDiff";
// import CategoryInfoCard from "../CategoryInfoCard";
import ReportContentProps from "./props";
import Tabs from "../Tabs";
// import Paragraph from "../Paragraph";
import OverviewAnalysis from "./OverviewAnalysis";
import HeuristicEvaluation from "./HeuristicEvaluation";
import TechnicalPerformance from "./TechnicalPerformance";
import AnalyticsIntegration from "./AnalyticsIntegration";
import React from "react";
import DownloadPDf from "./DownloadPDF";
import { useCookies } from "react-cookie";

// function onlyUnique(value: any, index: any, array: any) {
//   return array.indexOf(value) === index;
// }

const ReportContent = ({
  data,
  currentDate,
  restartAudit,
  signUp,
}: ReportContentProps) => {
  const [cookies] = useCookies(["tokens"]); // Access cookies
  const hasToken = Boolean(cookies.tokens?.access); // Check if the access token exists

  console.log(data);

  const tabsData = [
    {
      name: "Overview Analysis",
      key: "tab1",
      content: <OverviewAnalysis data={data} />,
    },
    {
      name: "Heuristic Evaluation",
      key: "tab2",
      content: <HeuristicEvaluation data={data} />,
    },
    {
      name: "Technical Performance",
      key: "tab3",
      content: <TechnicalPerformance data={data} />,
    },
    {
      name: "Analytics Integration",
      key: "tab4",
      content: <AnalyticsIntegration data={data} />,
    },
  ];

  return (
    <div id="reportContent">
      <div style={{ width: "100%" }}>
        <div className={`${styles.buttonContainer}`}>
          <div className={`${styles.reportCreated}`}>{currentDate}</div>
          <div className={`${styles.buttonGroup}`}>
            <Button onClick={restartAudit} type="primary">
              <RiRestartLine size={18} /> RESTART AUDIT
            </Button>
            <div className={styles.buttonSpacer}></div>
            <DownloadPDf 
              data={data} 
              currentDate={currentDate} 
              disabled={!hasToken} 
            />
          </div>
        </div>
      </div>
      <Divider />
      <Tabs tabs={tabsData} />

      {/* <Paragraph title="Report presentation" paragraph="" />
      <div style={{ marginBottom: "1rem" }}>
        <Markdown>
          {data.sectionsText["genral-introduction"].message.message.content}
        </Markdown>
      </div>

      <h2 style={{ fontSize: "2rem", margin: "0.25em 0" }}>General</h2>
      <h3 style={{ fontSize: "1.2rem", margin: "0.25em 0", fontWeight: 600 }}>
        Redirections
      </h3>
      <h4 style={{ fontSize: "1rem", margin: "0.25em 0", fontWeight: 600 }}>
        Mobile
      </h4>
      <UrlDiff
        url={data.general.redirections.requestedUrl}
        finalUrl={data.general.redirections.finalUrl.mobile}
      />
      <h4 style={{ fontSize: "1rem", margin: "0.25em 0", fontWeight: 600 }}>
        Desktop
      </h4>
      <UrlDiff
        url={data.general.redirections.requestedUrl}
        finalUrl={data.general.redirections.finalUrl.desktop}
      />
      <h3 style={{ fontSize: "1.2rem", margin: "0.25em 0", fontWeight: 600 }}>
        Screenshots
      </h3>
      {data.general.screenshots && (
        <div style={{ marginBottom: "1rem" }}>
          <Screenshots
            hasDesktop={!!data.general.screenshots.desktop.length}
            hasMobile={!!data.general.screenshots.mobile.length}
            desktopScreenshot={data.general.screenshots.desktop}
            mobileScreenshot={data.general.screenshots.mobile}
          />
        </div>
      )}
      </div>
      <div className="pdf-section">
      <h2 style={{ fontSize: "2rem", margin: "0.25em 0" }}>Usability</h2>
      <div style={{ marginBottom: "1rem" }}>
        <Markdown>
          {
            data.sectionsText["section-usability-description"].message.message
              .content
          }
        </Markdown>
      </div>
      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Summary
      </h3>
      <div style={{ marginBottom: "1rem" }}>
        <Markdown>
          {data.sectionsText["section-usability-recap"].message.message.content}
        </Markdown>
      </div>
      </div>
      <div className="pdf-section">
      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Responsiveness
      </h3>
      <div style={{ display: "flex" }}>
        <CategoryInfoCard
          value={data.usability.responsiveness.isMobileFriendly ? "Yes" : "No"}
          name="Has a mobile viewport"
          working={false}
          status={
            data.usability.responsiveness.isMobileFriendly ? "good" : "bad"
          }
          numberOfCards={2}
          full
        />
        <CategoryInfoCard
          value={data.usability.responsiveness.isMobileFriendly ? "Yes" : "No"}
          name="Has dedicated mobile URL"
          working={false}
          numberOfCards={2}
        />
      </div>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.usability.responsiveness.mobilePerformance * 100}
          status={
            data.usability.responsiveness.mobilePerformance > 0.8
              ? "good"
              : data.usability.responsiveness.mobilePerformance > 0.5
              ? "medium"
              : "bad"
          }
          name="Mobile Performance"
          working={false}
          numberOfCards={2}
        />
        <CategoryInfoCard
          value={(
            data.usability.responsiveness.perfomanceRelativeToDesktop * 100
          ).toFixed(2)}
          status={
            data.usability.responsiveness.perfomanceRelativeToDesktop >= 0
              ? "good"
              : data.usability.responsiveness.perfomanceRelativeToDesktop >=
                -0.2
              ? "medium"
              : "bad"
          }
          name="Mobile Performance relative to desktop"
          working={false}
          numberOfCards={2}
          unit="%"
          full
        />
      </div>
</div>
      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Accessibility
      </h3>
      <CategoryScoreCard
        score={data.usability.accessibility.score * 100}
        status={
          data.usability.accessibility.score > 0.8
            ? "good"
            : data.usability.accessibility.score > 0.5
            ? "medium"
            : "bad"
        }
        name="Score"
        working={false}
        numberOfCards={1}
      />
      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Interactivity
      </h3>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.usability.interactivity.speedIndex * 100}
          status={
            data.usability.interactivity.speedIndex > 0.8
              ? "good"
              : data.usability.interactivity.speedIndex > 0.5
              ? "medium"
              : "bad"
          }
          name="Interactivity"
          working={false}
          numberOfCards={2}
        />
        <CategoryScoreCard
          score={data.usability.interactivity.firstPaint * 100}
          status={
            data.usability.interactivity.firstPaint > 0.8
              ? "good"
              : data.usability.interactivity.firstPaint > 0.5
              ? "medium"
              : "bad"
          }
          name="First Paint"
          working={false}
          numberOfCards={2}
        />
      </div>
      <div style={{ display: "flex" }}>
        <CategoryInfoCard
          value={(
            data.usability.interactivity.timeToInteractive.mobile / 1000
          ).toFixed(2)}
          name="Time To Interactive (Mobile)"
          working={false}
          numberOfCards={2}
          unit="seconds"
        />
        <CategoryInfoCard
          value={(
            data.usability.interactivity.timeToInteractive.desktop / 1000
          ).toFixed(2)}
          name="Time To Interactive (Desktop)"
          working={false}
          numberOfCards={2}
          unit="seconds"
        />
      </div>

      <h2 style={{ fontSize: "2rem", margin: "0.25em 0" }}>Design</h2>
      <div style={{ marginBottom: "1rem" }}>
        <Markdown>
          {
            data.sectionsText["section-design-description"].message.message
              .content
          }
        </Markdown>
      </div>
      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Colors
      </h3>
      <ColorsDetails data={data.design.colors.allDetailed} />

      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Fonts
      </h3>
      {Object.keys(data.design.fonts).map((font) => (
        <FontPreview key={font} name={font} count={data.design.fonts[font]} />
      ))}

      <h2 style={{ fontSize: "2rem", margin: "0.25em 0" }}>
        Security and Privacy
      </h2>
      <div style={{ marginBottom: "1rem" }}>
        <Markdown>
          {
            data.sectionsText["section-security-and-privacy-description"]
              .message.message.content
          }
        </Markdown>
      </div>
      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Summary
      </h3>
      <div style={{ marginBottom: "1rem" }}>
        <Markdown>
          {
            data.sectionsText["section-security-and-privacy-recap"].message
              .message.content
          }
        </Markdown>
      </div>
      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Cookies
      </h3>
      <div style={{ display: "flex" }}>
        <CategoryInfoCard
          value={
            data.securityAndPrivacy.cookies.firstParty ? "Used" : "Not used"
          }
          name="First-Party"
          working={false}
          numberOfCards={2}
        />
        <CategoryInfoCard
          value={
            data.securityAndPrivacy.cookies.thirdParty ? "Used" : "Not used"
          }
          name="Third-Party"
          working={false}
          status={
            data.securityAndPrivacy.cookies.thirdParty ? "medium" : "good"
          }
          numberOfCards={2}
          full
        />
      </div>

      <h3 style={{ fontSize: "1.5rem", margin: "0.25em 0", fontWeight: 600 }}>
        Secure HTTP (HTTPS)
      </h3>
      <CategoryInfoCard
        value={data.securityAndPrivacy.ssl.isHttps ? "Used" : "Not used"}
        name="Overall"
        working={false}
        status={data.securityAndPrivacy.ssl.isHttps ? "good" : "bad"}
        numberOfCards={1}
        full
      />
      <div style={{ display: "flex" }}>
        <CategoryInfoCard
          value={data.securityAndPrivacy.ssl.mobile ? "Used" : "Not used"}
          name="Mobile"
          working={false}
          status={data.securityAndPrivacy.ssl.mobile ? "good" : "bad"}
          numberOfCards={2}
          full
        />
        <CategoryInfoCard
          value={data.securityAndPrivacy.ssl.desktop ? "Used" : "Not used"}
          name="Desktop"
          working={false}
          status={data.securityAndPrivacy.ssl.desktop ? "good" : "bad"}
          numberOfCards={2}
          full
        />
      </div>

      <h2 style={{ fontSize: "2rem", margin: "0.25em 0" }}>
        Technical Performance
      </h2>
      <div style={{ marginBottom: "1rem" }}>
        <Markdown>
          {
            data.sectionsText["section-technical-perf-intro"].message.message
              .content
          }
        </Markdown>
      </div>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.technicalPerformance.generalScore.mobile * 100}
          status={
            data.technicalPerformance.generalScore.mobile > 0.8
              ? "good"
              : data.technicalPerformance.generalScore.mobile > 0.5
              ? "medium"
              : "bad"
          }
          name="Mobile"
          working={false}
          numberOfCards={2}
        />
        <CategoryScoreCard
          score={data.technicalPerformance.generalScore.desktop * 100}
          status={
            data.technicalPerformance.generalScore.desktop > 0.8
              ? "good"
              : data.technicalPerformance.generalScore.desktop > 0.5
              ? "medium"
              : "bad"
          }
          name="Desktop"
          working={false}
          numberOfCards={2}
        />
      </div>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.technicalPerformance.cls.mobile.score * 100}
          status={
            data.technicalPerformance.cls.mobile.score > 0.8
              ? "good"
              : data.technicalPerformance.cls.mobile.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.cls.title}
          working={false}
          numberOfCards={2}
        />
        <CategoryScoreCard
          score={data.technicalPerformance.cls.desktop.score * 100}
          status={
            data.technicalPerformance.cls.desktop.score > 0.8
              ? "good"
              : data.technicalPerformance.cls.desktop.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.cls.title}
          working={false}
          numberOfCards={2}
        />
      </div>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.technicalPerformance.fcp.mobile.score * 100}
          status={
            data.technicalPerformance.fcp.mobile.score > 0.8
              ? "good"
              : data.technicalPerformance.fcp.mobile.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.fcp.title}
          working={false}
          numberOfCards={2}
        />
        <CategoryScoreCard
          score={data.technicalPerformance.fcp.desktop.score * 100}
          status={
            data.technicalPerformance.fcp.desktop.score > 0.8
              ? "good"
              : data.technicalPerformance.fcp.desktop.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.fcp.title}
          working={false}
          numberOfCards={2}
        />
      </div>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.technicalPerformance.lcp.mobile.score * 100}
          status={
            data.technicalPerformance.lcp.mobile.score > 0.8
              ? "good"
              : data.technicalPerformance.lcp.mobile.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.lcp.title}
          working={false}
          numberOfCards={2}
        />
        <CategoryScoreCard
          score={data.technicalPerformance.lcp.desktop.score * 100}
          status={
            data.technicalPerformance.lcp.desktop.score > 0.8
              ? "good"
              : data.technicalPerformance.lcp.desktop.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.lcp.title}
          working={false}
          numberOfCards={2}
        />
      </div>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.technicalPerformance.si.mobile.score * 100}
          status={
            data.technicalPerformance.si.mobile.score > 0.8
              ? "good"
              : data.technicalPerformance.si.mobile.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.si.title}
          working={false}
          numberOfCards={2}
        />
        <CategoryScoreCard
          score={data.technicalPerformance.si.desktop.score * 100}
          status={
            data.technicalPerformance.si.desktop.score > 0.8
              ? "good"
              : data.technicalPerformance.si.desktop.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.si.title}
          working={false}
          numberOfCards={2}
        />
      </div>
      <div style={{ display: "flex" }}>
        <CategoryScoreCard
          score={data.technicalPerformance.tbt.mobile.score * 100}
          status={
            data.technicalPerformance.tbt.mobile.score > 0.8
              ? "good"
              : data.technicalPerformance.tbt.mobile.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.tbt.title}
          working={false}
          numberOfCards={2}
        />
        <CategoryScoreCard
          score={data.technicalPerformance.tbt.desktop.score * 100}
          status={
            data.technicalPerformance.tbt.desktop.score > 0.8
              ? "good"
              : data.technicalPerformance.tbt.desktop.score > 0.5
              ? "medium"
              : "bad"
          }
          name={data.technicalPerformance.tbt.title}
          working={false}
          numberOfCards={2}
        />
      </div>

      <h2 style={{ fontSize: "2rem", margin: "0.25em 0" }}>
        Social Integration and Sharing
      </h2>
      <div>Detected social links:</div>
      <div>
        {!!data.socialIntegration.links.length
          ? data.socialIntegration.links
              .filter(onlyUnique)
              .map((link: string) => (
                <div key={link}>
                  <a href={link}>{link}</a>
                </div>
              ))
          : "None"}
      </div>

      {data?.colors?.all && Object.keys(data.data.colors.all)?.length > 0 && (
        <ColorsRecap colors={Object.keys(data.data.colors.all)} />
      )}

      {data.data?.fontsDetailed &&
        Object.keys(data.data?.fontsDetailed).length > 0 && (
          <>
            <div
              style={{
                fontSize: "2rem",
                fontWeight: 600,
                marginBottom: "0.5rem",
              }}
            >
              Fonts
            </div>
            {Object.keys(data.data?.fontsDetailed).map((font) => (
              <FontPreview
                key={font}
                name={data.data?.fontsDetailed[font].name}
                count={data.data?.fontsDetailed[font].count}
                preview={data.data?.fontsDetailed[font].preview}
              />
            ))}
            <Divider />
          </>
        )} */}
    </div>
  );
};

export default ReportContent;
