import { useState, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import styles from "./ResetPassword.module.scss";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { token } = useParams();

  const resetPasswordMutation = useMutation({
    mutationFn: () =>
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/reset-password?token=${token}`,
        { password }
      ),
    onError: (error) => {
      setError(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "Something went wrong"
      );
    },
    onSuccess: () => {
      navigate("/");
    },
  });

  const ResetPassword = useCallback(() => {

    if (!password.trim()) {
      setError("Please fill in your Password");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords must match");
      return;
    }
    if (error) {
      setError(null);
    }
  
    resetPasswordMutation.mutate();
  }, [password, confirmPassword, error, resetPasswordMutation]);
  const signIn = () => {
    navigate("/signin");
  };

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.grayCard}`}></div> 
      <div className={`${styles.resetPasswordCard}`}>
      <div className={`${styles.visosphere}`}>Visosphere.</div>
        <div className={`${styles.title}`}>Reset Password</div>
        <div className={`${styles.sentence}`}> Enter a new password to reset the password on your account. <br></br>We'll ask for this password whenever you log in.</div>
           {error && <div className={`${styles.serverError}`}>{error}</div>}
           
        <Input
          label="New Password"
          block
          type="password"
          value={password}
          onChange={setPassword}
        />
        <Input
          label="Confirm Password"
          block
          type="password"
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
        <Button block type="primary" onClick={ResetPassword}>
          Reset Password{" "}
        </Button>
        <div className={styles.signInLink}>
        Know your password? {' '}
          <span onClick={signIn} className={styles.signInLink}>
             Sign in
          </span>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
