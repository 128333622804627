export const testURLPrefix = (url: string): boolean =>
  !!(url?.length && url.match(/^[a-zA-Z]+:\/\//));

const prependURL = (url: string): string => {
  if (url?.length && !url.match(/^[a-zA-Z]+:\/\//)) {
    return `http://${url}`;
  }

  return url;
};

export default prependURL;
