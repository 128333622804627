import { useCallback, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import {useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IoEye , IoEyeOff } from "react-icons/io5";
import Button from "../../Components/UI/Button";
import Input from "../../Components/UI/Input";
import styles from "./Signup.module.scss";
import { Errors } from "./props";
import { useMutation } from "@tanstack/react-query";
import { signup } from "../APICalls/user";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const [first_name, setFirstName] = useState<string>("");
  const [last_name, setLastName] = useState<string>("");
  const [phone_number, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [, setCookie] = useCookies(["tokens", "user"]);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Define a function to toggle the state of the checkbox
  const toggleAgreeToTerms = () => {
    setAgreeToTerms(prevState => !prevState);
  };

  const signupMutation = useMutation({
    mutationFn: async () => {
    if (!executeRecaptcha) {
      throw new Error("reCAPTCHA is not initialized");
    }
    const recaptchaToken = await executeRecaptcha("signup");
      return signup(
        first_name,
        last_name,
        email,
        password,
        phone_number,
        recaptchaToken,
      );
    },
    onError: (error) => {
      setErrors((old) => ({
        ...old,
        server: axios.isAxiosError(error)
          ? error?.response?.data.message
          : "something went wrong",
      }));
    },
    onSuccess: (data) => {
      if (data?.data?.tokens) {
        setCookie("tokens", JSON.stringify(data.data.tokens));
      }
      if (data?.data?.user) {
        setCookie("user", JSON.stringify(data.data.user));
      }
     
      navigate("/audit");
    },
  });

  const signIn = () => {
    navigate("/signin");
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  const signUp = useCallback(() => {
    let hasError = false;
    const newErrors: Errors = {};

    if (!first_name.trim()) {
      newErrors.first_name = "Please fill in your First Name";
      hasError = true;
    }
    if (!last_name.trim()) {
      newErrors.last_name = "Please fill in your Last Name";
      hasError = true;
    }

    if (!email.trim()) {
      newErrors.email = "Please fill in your Email";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid Email";
      hasError = true;
    }

    if (!password.trim()) {
      newErrors.password = "Please fill in your Password";
      hasError = true;
    } else if (password.trim().length < 8) {
      newErrors.password = "Password must be at least 8 characters";
      hasError = true;
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords must match";
      hasError = true ;
    }

    if (!phone_number.trim()) {
      newErrors.phone_number = "Please fill in your Phone number";
      hasError = true;
    } else if (!/^\d+$/.test(phone_number)) {
      newErrors.phone_number = "Phone number must contain only numbers";
      hasError = true;
    }
    
    setErrors({});
    if (!hasError) {
      setErrors({});
      signupMutation.mutate();
    } else {
      setErrors((old) => ({ ...old, ...newErrors }));
    }
  }, [
    first_name,
    last_name,
    email,
    password,
    confirmPassword,
    phone_number,
    signupMutation,
  ]);

const loginGoogle = useGoogleLogin({
  onSuccess: async ({ code  }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/google`, {
        code,
      });
      setCookie("tokens", JSON.stringify(response.data.tokens));
      setCookie("user", JSON.stringify(response.data.user));
      
      navigate("/audit");

    } catch (error) {
      console.error('Error logging in with Google:', error);

    }
  },
  flow: 'auth-code',
});
const Home = () => {
  navigate("/");
};
  return (
    <div className={`${styles.wrapper}`}>
     <div className={`${styles.grayCard}`}>   <img src="melio.png" alt="Error Illustration" width={700}  className={styles.roundedImage}/></div> 
      <div className={`${styles.signupCard}`}>
      <div className={styles.backContainer}>
      <div className={`${styles.visosphere}`} onClick={Home}> Visosphere. </div>
        <Link to="/" className={`${styles.backButton}`}>
        &rarr;
        </Link>

      </div>
        <div className={`${styles.title}`}>Sign Up</div>
        <div className={`${styles.sentence}`}> Let’s get you all set up so you can access your account</div>
        {errors.server ? (
          <div className={`${styles.serverError}`}>{errors.server}</div>
        ) : null}
        <div className={`${styles.containerWrapper}`}>
          <div className={`${styles.inputContainer}`}>
            <Input
              label="First Name"
              block
              type="text"
              onChange={setFirstName}
              error={errors.first_name}
              placeholder="Enter your first name"
            />
            <Input
              label="Email"
              block
              type="email"
              onChange={setEmail}
              error={errors.email}
              placeholder="Enter your email"
            />
          </div>
          <div className={`${styles.inputContainer}`}>
            <Input
              label="Last Name"
              block
              type="text"
              onChange={setLastName}
              error={errors.last_name}
              placeholder="Enter your last name"
            />
            <Input
              label="Phone Number"
              block
              type="text"
              onChange={setPhoneNumber}
              error={errors.phone_number}
              placeholder="Enter your Phone Number"
            />
     
          </div>
        </div>
        <div className={`${styles.inContainer}`}>
        <Input
          label="Password"
          block
          type={showPassword ? "text" : "password"}
          onChange={setPassword}
          error={errors.password}
          placeholder="Enter your password (at least 8 characters)"
          icon={showPassword ? IoEyeOff : IoEye}
          onIconClick={togglePasswordVisibility}
        />
        <Input
          label="Confirm Password"
          block
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          error={errors.password}
          onChange={setConfirmPassword}
          placeholder="Confirm your password"
          icon={showConfirmPassword ? IoEyeOff : IoEye}
          onIconClick={toggleConfirmPasswordVisibility}
        />
       <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          checked={agreeToTerms}
          onChange={toggleAgreeToTerms}
          className={styles.checkbox}
        />
        {' '} I agree to all the <a href="/">Terms</a> and <a href="/">Privacy policies</a>
       </label>
          <Button block type="primary" onClick={signUp}>
          Create account
        </Button>
  
       </div>

        <div className={styles.signInLink}>
          Already have an account? {' '}
          <span onClick={signIn} className={styles.signInLink}>
            Sign in
          </span>
        </div>
        <div className={styles.orDivider}>
          <div className={styles.dividerContainer}>
            <hr className={styles.dividerLine} />
            <span className={styles.orText}>Or</span>
            <hr className={styles.dividerLine} />
          </div>
        </div>
        <Button
          block
          type="google"
          onClick={loginGoogle}
        >
          <img src="googleicon.png" alt="google" />
          <span >  Sign up with Google Account </span>
       
        </Button>
      </div>
    </div>
  );
};

export default Signup;
