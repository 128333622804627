import styles from "./screenshots.module.scss";
import ScreenshotsProps from "./props";

const Screenshots = ({
  hasDesktop,
  hasMobile,
  desktopScreenshot,
  mobileScreenshot,
}: ScreenshotsProps) => {
  return (
    <>
      <div
        className={`${styles.screenshots}${
          hasMobile ? ` ${styles.hasMobile}` : ""
        }`}
      >
        <div
          className={`${styles.screenshotFrame} ${styles.desktop}${
            hasDesktop ? ` ${styles.shown}` : ""
          }`}
        >
          <img src={desktopScreenshot} width="100%" height="auto" alt="" />
        </div>
        <div
          className={`${styles.screenshotFrame} ${styles.mobile}${
            hasMobile ? ` ${styles.shown}` : ""
          }`}
        >
          <img src={mobileScreenshot} height={448} width="auto" alt="" />
        </div>
      </div>
    </>
  );
};

export default Screenshots;
