import React, { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { useQuery } from "@tanstack/react-query";
import { fetchSubscriptionPlans } from "../APICalls/subscriptionPlan";
import styles from "./SubscriptionPlan.module.scss";
import Navbar from "../../Components/Navbar";

interface Plan {
  id: string;
  name: string;
  monthly_rate: number;
  yearly_rate?: number;
  currency: string;
  active: boolean;
  monthly_tokens: number;
  monthly_websites: number;
  urls_per_website: number;
  report_tier: number;
  country_codes: string[];
  characteristics: Characteristic[];
}

interface Characteristic {
  category: string;
  content: string;
}

const SubscriptionPlan = () => {
  const [cookies] = useCookies(["tokens", "userId"]);
  const [selectedPlan] = useState<string>("");
  const [couponCode, setCouponCode] = useState<string>("");
  const [showMonthly, setShowMonthly] = useState<boolean>(true);

  const queryFn = useCallback(
    async () => await fetchSubscriptionPlans(cookies.tokens.access.token),
    [cookies.tokens.access.token]
  );

  // Use React Query to fetch subscription plans
  const {
    data: plans = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["subscriptionPlans"],
    queryFn: queryFn,
  });

  const toggleRate = () => {
    setShowMonthly(!showMonthly);
  };

  const handleCheckout = () => {
    // console.log('Proceeding to checkout with coupon code:', couponCode);
  };

  if (isLoading) {
    return <div>Loading plans...</div>;
  }

  if (isError) {
    return <div>Error loading subscription plans</div>;
  }

  return (
    <div>
      <Navbar />
      <div className={`${styles.container}`}>
        <div className={`${styles.title}`}>Pick your suitable package</div>
        <div className={`${styles.description}`}>
          Begin your journey with Us at no upfront cost
        </div>
        <div className={`${styles.tableContainer}`}>
          <table className={`${styles.table}`}>
            <tbody>
              <tr>
                <th>Select Plan</th>
                {plans.map((plan: Plan) => (
                  <td key={plan.id}>{plan.name}</td>
                ))}
              </tr>
              <tr>
                <th>
                  <button className={`${styles.button}`} onClick={toggleRate}>
                    {showMonthly ? "Yearly Rate" : "Monthly Rate"}
                  </button>
                </th>
                {plans.map((plan: Plan) => (
                  <td key={plan.id}>
                    {showMonthly
                      ? `${plan.currency} ${plan.monthly_rate}`
                      : plan.yearly_rate
                      ? `${plan.currency} ${plan.yearly_rate}`
                      : "N/A"}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        {selectedPlan && (
          <div>
            <h3>Have a coupon code?</h3>
            <input
              type="text"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              placeholder="Enter coupon code"
            />
          </div>
        )}
        <div className={`${styles.couponContainer}`}>
          <h3 className={`${styles.couponTitle}`}>Have a coupon code?</h3>
          <input
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            placeholder="Enter coupon code"
            className={styles.couponInput}
          />
          <button
            className={`${styles.checkoutButton}`}
            onClick={handleCheckout}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
