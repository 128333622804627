import { CardProps } from "./props";
import styles from "./Card.module.scss";

const Card = ({ children, style, className }: CardProps) => {
  return (
    <div
      style={style}
      className={`${styles.card} ${className ? `${className}` : ""}`}
    >
      {children}
    </div>
  );
};

export default Card;
