import ParagraphProps from "./props";
import styles from "./paragraph.module.scss";
import Markdown from "react-markdown";

const Paragraph = ({ title, paragraph, style, markDown }: ParagraphProps) => {
  return (
    <div className={styles.paragraphContainer} style={style}>
      <h2 className={styles.title}>{title}</h2>
      {paragraph ? (
        <div>{markDown ? <Markdown>{paragraph}</Markdown> : paragraph}</div>
      ) : null}
    </div>
  );
};

export default Paragraph;
