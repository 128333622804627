import { useEffect } from "react";
import Paragraph from "../Paragraph";
import ReportCardsGroup from "../ReportCardsGroup";
import ScoreCard from "../ScoreCard";
import Screenshots from "../Screenshots";
import { ReportSectionProps } from "./props";
import ReportTab from "./ReportTab";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const OverviewAnalysis = ({ data }: ReportSectionProps) => {
  const navigate = useNavigate(); 
  const [cookie] = useCookies(["tokens", "user", "_id"]);
  const isConnected = !!cookie.tokens?.access?.token;


  const signUp = () => {
    navigate("/signup");
  };
  
  useEffect(() => {
    if (!isConnected) {
      const timer = setTimeout(signUp, 60000);
      return () => clearTimeout(timer); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  return (
    <ReportTab>
      <ReportCardsGroup
        title="Overall app health"
        description="View the individual factors that contributed to your health score"
      >
        <ScoreCard
          title="Overall score"
          value={`${Math.floor(data.general.overviewAverageScore * 100)}%`}
          status={
            data.general.overviewAverageScore > 0.9
              ? "good"
              : data.general.overviewAverageScore > 0.5
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="Best practises"
          value={`${Math.floor(
            data.bestPractices.generalScore.average * 100
          )}%`}
          status={
            data.bestPractices.generalScore.average > 0.9
              ? "good"
              : data.bestPractices.generalScore.average > 0.5
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="Accessibility"
          value={`${Math.floor(data.usability.accessibility.score * 100)}%`}
          status={
            data.usability.accessibility.score > 0.9
              ? "good"
              : data.usability.accessibility.score > 0.5
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="Interactivity"
          value={`${Math.floor(
            data.usability.interactivity.speedIndex * 100
          )}%`}
          status={
            data.usability.interactivity.speedIndex > 0.9
              ? "good"
              : data.usability.interactivity.speedIndex > 0.5
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="Performance"
          value={`${Math.floor(
            data.technicalPerformance.generalScore.average * 100
          )}%`}
          status={
            data.technicalPerformance.generalScore.average > 0.9
              ? "good"
              : data.technicalPerformance.generalScore.average > 0.5
              ? "medium"
              : "bad"
          }
        />
      </ReportCardsGroup>
      <Paragraph
        title="Report Presentation"
        paragraph={
          data.sectionsText["genral-introduction"].message.message.content
        }
        markDown
      />
      <Screenshots
        hasDesktop={
          !!data.general.screenshots.desktopHQ?.length ||
          !!data.general.screenshots.desktop.length
        }
        hasMobile={!!data.general.screenshots.mobile.length}
        desktopScreenshot={
          !!data.general.screenshots.desktopHQ?.length
            ? data.general.screenshots.desktopHQ
            : data.general.screenshots.desktop
        }
        mobileScreenshot={data.general.screenshots.mobile}
      />
    </ReportTab>
  );
};

export default OverviewAnalysis;

