import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import dayjs from "../../utils/dayjs";
import { ConnectionRouteProps } from "./props";

const ConnectionRoute = ({ redirect }: ConnectionRouteProps) => {
  const [cookie] = useCookies(["tokens"]);

  return cookie.tokens?.access?.token &&
    cookie.tokens?.access?.expires &&
    dayjs(cookie.tokens?.access?.expires)
      .subtract(1, "millisecond")
      .isAfter(dayjs()) ? (
    <Navigate to={redirect} />
  ) : (
    <Outlet />
  );
};

export default ConnectionRoute;
