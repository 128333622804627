import  { useState } from 'react';
import Input from '../Input';
import Modal from '../Modal';
import BaseSelect from '../Select';
import CreateSelect from '../CreatableSelect';
import Button from '../Button';
import styles from './UserModal.module.scss';

interface Option {
    readonly label: string;
    readonly value: string;
}
const createOption = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
});
const defaultIndustries = [
    createOption("Agriculture"),
    createOption("Industrials"),
    createOption("Energy"),
    createOption("Utilities"),
    createOption("Transport"),
    createOption("Logistics"),
    createOption("Media"),
    createOption("Creative Industries"),
    createOption("Data"),
    createOption("Telecom"),
    createOption("Healthcare"),
    createOption("Education"),
    createOption("Lifesciences"),
    createOption("Retail"),
    createOption("Ecommerce"),
    createOption("Hospitality"),
    createOption("Leisure Travel"),
    createOption("SocialService"),
    createOption("FinancialServices"),
    createOption("Law"),
    createOption("Consulting"),
    createOption("IT"),
    createOption("Marketing"),
    createOption("SoftwareDevelopment"),
];

const defaultCompanysize = [
    { label: "1 to 10", value: "1-10" },
    { label: "11 to 25", value: "11-25" },
    { label: "26 to 50", value: "26-50" },
    { label: "51 to 200", value: "51-200" },
    { label: "201 to 1 000", value: "201-1000" },
    { label: "1 001 to 10 000", value: "1001-10000" },
    { label: "10 001 or more", value: "10001+" },
];
const specialities = [
    { label: "Product", value: "product" },
    { label: "Designer", value: "designer" },
    { label: "Engineer", value: "engineer" },
    { label: "Analyst", value: "analyst" },
    { label: "Marketing", value: "marketing" },
    { label: "UX Researcher", value: "researcher" },
    { label: "Owner", value: "owner" },
    { label: "Other", value: "other" },
];

const UserModal = ({ isOpen, onClose, onSubmit }: { isOpen: boolean, onClose: () => void, onSubmit: (data: any) => void }) => {
  const [company_name, setCompanyName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [industry, setIndustry] = useState<Option | null>(null);
  const [company_size, setCompanySize] = useState<Option | null>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedSpeciality, setSelectedSpeciality] = useState<string | null>(null);

  const handleCreateOption = () => {};
  const handleSpecialityClick = (value: string) => {
    setSelectedSpeciality(value);
  };

  const handleStartButtonClick = () => {
    let hasError = false;
    const newErrors: { [key: string]: string } = {};

    if (!company_name.trim()) {
      newErrors.company_name = 'Please enter your company name';
      hasError = true;
    }

    if (!country.trim()) {
      newErrors.country = 'Please enter your country';
      hasError = true;
    }

    if (!company_size) {
      newErrors.company_size = 'Please choose your company size';
      hasError = true;
    }

    if (!industry) {
      newErrors.industry = 'Please choose your industry';
      hasError = true;
    }

    setErrors(newErrors);

    if (!hasError) {
      onSubmit({
        company_name,
        country,
        industry: industry?.value,
        company_size: company_size?.value,
        speciality: selectedSpeciality,
      });
      onClose();
    }
  };

  const specialityButtons = specialities.map((speciality, index) => (
    <Button
      key={index}
      onClick={() => handleSpecialityClick(speciality.value)}
      className={
        selectedSpeciality === speciality.value ? styles.selectedSpeciality : ''
      }
    >
      {speciality.label}
    </Button>
  ));

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClick={handleStartButtonClick}
      title="Welcome to Visosphere"
      buttons={[
        <div className={styles.specialityButtonContainer}>
          <div className={styles.specialityButtonGroup}>
            {specialityButtons.slice(0, 2)}
            {specialityButtons.slice(2, 4)}
          </div>
          <div className={styles.specialityButtonGroup}>
            {specialityButtons.slice(4, 6)}
            {specialityButtons.slice(6, 8)}
          </div>
        </div>,
      ]}
      inputs={[
        <Input
          key="companyName"
          label="Company Name"
          block
          type="text"
          value={company_name}
          onChange={(value: string) => setCompanyName(value)}
          error={errors.company_name}
          placeholder="Enter your company name"
        />,
        <Input
          key="country"
          label="Country"
          block
          type="text"
          value={country}
          onChange={(value: string) => setCountry(value)}
          error={errors.country}
          placeholder="Enter your country"
        />,
        <BaseSelect
        key="companySize"
        label="Company Size"
        isClearable
        onChange={(newValue) => setCompanySize(newValue)}
        options={defaultCompanysize}
        value={company_size}
        error={errors.company_size}
        placeholder="- Choose Company Size -"
      />,
        <CreateSelect
        key="industry"
        label="Industry"
        defaultOptions={defaultIndustries}
        onCreateOption={handleCreateOption}
        onChange={setIndustry}
        value={industry}
        error={errors.industry}
        placeholder="- Choose Industry -"
      />,
      ]}
    />
  );
};

export default UserModal;
