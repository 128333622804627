import chroma from "chroma-js";
import styles from "./colors-details.module.scss";
import ColorsDetailsProps from "./props";

const ColorsDetails = ({ data }: ColorsDetailsProps) => {
  return (
    <div className={`${styles.colorsDetails}`}>
      {Object.keys(data).map((color) => {
        const colorObj = chroma(color);
        return (
          <div key={color} className={`${styles.colorCard}`}>
            <div
              className={`${styles.colorPreview}`}
              style={{
                backgroundColor: colorObj.hex(),
                color:
                  colorObj.alpha() < 0.5 ||
                  chroma.contrast(colorObj.hex(), "white") < 4.5
                    ? undefined
                    : "white",
                borderColor:
                  colorObj.alpha() < 0.5 ||
                  chroma.contrast(colorObj.hex(), "white") < 4.5
                    ? undefined
                    : "transparent",
              }}
            >
              <div className={`${styles.code}`}>
                {colorObj.alpha() !== 1
                  ? `rgba(${colorObj.rgba()[0]}, ${colorObj.rgba()[1]}, ${
                      colorObj.rgba()[2]
                    }, ${colorObj.rgba()[3]})`
                  : colorObj.hex().toUpperCase()}
              </div>
              <div>{`Used ${data[color].count} time${
                data[color].count === 1 ? "" : "s"
              } on this page`}</div>
            </div>
            {data[color].pairings?.length ? (
              <>
                <div className={`${styles.pairingsTitle}`}>Pairings</div>
                <div className={`${styles.pairings}`}>
                  {data[color].pairings.map((pair: string) => {
                    const pairObj = chroma(pair);
                    const contrast = chroma.contrast(
                      pairObj.hex(),
                      colorObj.hex()
                    );
                    return (
                      <div
                        className={`${styles.pair}`}
                        style={{
                          backgroundColor: pairObj.hex(),
                          color: colorObj.hex(),
                        }}
                      >
                        <div className={`${styles.score}`}>
                          {contrast < 3
                            ? "FAIL"
                            : contrast < 4.5
                            ? "AA+"
                            : contrast < 7
                            ? "AA"
                            : "AAA"}
                        </div>
                        <div>{contrast.toFixed(2)}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
        );
      })}
      
    </div>
  );
};

export default ColorsDetails;
