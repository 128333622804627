import Markdown from "react-markdown";
import AnalysisBox from "../AnalysisBox";
import Paragraph from "../Paragraph";
import ReportCardsGroup from "../ReportCardsGroup";
import ScoreCard from "../ScoreCard";
import { ReportSectionProps } from "./props";
import ReportTab from "./ReportTab";
import { useCookies } from "react-cookie";
import styles from "./reportContent.module.scss";
import { useNavigate } from "react-router-dom";

const TechnicalPerformance = ({ data }: ReportSectionProps) => {
  const [cookie] = useCookies(["tokens", "user", "_id"]);
  const isConnected = !!cookie.tokens?.access?.token;
  const navigate = useNavigate();

  const signUp = () => {
    navigate("/signup");
  };
  return (
    <ReportTab>
          {isConnected ? (
        <>
      <Paragraph
        title="Overview"
        paragraph={
          data.sectionsText["section-technical-perf-intro"].message.message
            .content
        }
      />
      <ReportCardsGroup
        title="Overall app health"
        description="View the individual factors that contributed to your health score"
      >
        <ScoreCard
          title="Desktop Performance"
          value={`${data.technicalPerformance.generalScore.desktop * 100}%`}
          status={
            data.technicalPerformance.generalScore.desktop > 0.9
              ? "good"
              : data.technicalPerformance.generalScore.desktop > 0.5
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="Mobile Performance"
          value={`${data.technicalPerformance.generalScore.mobile * 100}%`}
          status={
            data.technicalPerformance.generalScore.mobile > 0.9
              ? "good"
              : data.technicalPerformance.generalScore.mobile > 0.5
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="Mobile Performance compared to desktop"
          value={`${(
            data.usability.responsiveness.perfomanceRelativeToDesktop * 100
          ).toFixed(2)}%`}
          status={
            data.usability.responsiveness.perfomanceRelativeToDesktop >= 0
              ? "good"
              : data.usability.responsiveness.perfomanceRelativeToDesktop >=
                - 0.2
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="First paint"
          value={`${data.usability.interactivity.firstPaint * 100}%`}
          status={
            data.usability.interactivity.firstPaint > 0.9
              ? "good"
              : data.usability.interactivity.firstPaint > 0.5
              ? "medium"
              : "bad"
          }
        />
        <ScoreCard
          title="Last paint"
          value={`${data.technicalPerformance.lcp.desktop.score * 100}%`}
          status={
            data.technicalPerformance.lcp.desktop.score > 0.9
              ? "good"
              : data.technicalPerformance.lcp.desktop.score > 0.5
              ? "medium"
              : "bad"
          }
        />
      </ReportCardsGroup>
      <h2>
        Overall Performance (
        {Math.floor(data.general.overviewAverageScore * 100)}/100)
      </h2>
      <Markdown>
        {
          data.sectionsText["section-technical-performance-analysis-short"]
            .message.message.content
        }
      </Markdown>
      <h2>Key Metrics</h2>
      <ul>
        <li>{`First Contentful Paint (FCP) - ${
          data.technicalPerformance.fcp.desktop.value / 1000
        }s on desktop and ${
          data.technicalPerformance.fcp.mobile.value / 1000
        }s on mobile: This measures how long it takes for the first piece of content (text or image) to appear on the screen
              Ideally, this should be under 2 seconds.`}</li>
        <li>{`Largest Contentful Paint (LCP) - ${
          data.technicalPerformance.lcp.desktop.value / 1000
        }s on desktop and ${
          data.technicalPerformance.lcp.mobile.value / 1000
        }s: This measures the time it takes for the largest content element (often the main image or text block) to load
              This should also be well under 2 seconds for a good user experience.`}</li>
        <li>{`Total Blocking Time (TBT) - ${data.technicalPerformance.tbt.desktop.value}ms on desktop and ${data.technicalPerformance.tbt.mobile.value}ms: This indicates the total time that scripts or other tasks block the main thread, preventing the browser from responding to user input.`}</li>
        <li>{`Cumulative Layout Shift (CLS) - ${data.technicalPerformance.cls.desktop.value} on desktop and ${data.technicalPerformance.cls.mobile.value}: This measures how much the page layout shifts around visually as elements load.
              A CLS of 0.1 or lower is considered good.`}</li>
        <li>{`Speed Index - ${
          data.technicalPerformance.si.desktop.value / 1000
        }s on desktop and ${
          data.technicalPerformance.si.mobile.value / 1000
        }s: This is a general measure of how quickly content is visually displayed during page load. A lower Speed Index indicates a faster perceived loading experience.`}</li>
      </ul>
      <AnalysisBox
        title="Key Findings"
        description={
          data.sectionsText["section-technical-performance-key-findings"]
            .message.message.content
        }
        question
        type="error"
      />
      <AnalysisBox
        title="Top recommendations for you"
        description={
          data.sectionsText["section-technical-performance-top-recommendations"]
            .message.message.content
        }
        question
        type="solution"
      />
            </>  ) : (
             <div className={`${styles.boxsignup}`}>
      
             <span onClick={signUp} className={styles.link}>
              Sign up
            </span>
            <p className={styles.p}>to access more detailed reports.</p> 
         </div>
      )}
    </ReportTab>
  );
};

export default TechnicalPerformance;
