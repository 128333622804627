import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  section: {
    marginBottom: 10,
    padding: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    textAlign: 'justify',
  },
  screenshot: {
    width: '300px',
    marginBottom: 4,
  },
});

const ReportPDF = ({ data, currentDate }: any) => (
  <Document>
    <Page style={styles.page}>
      {/* Report Header */}
      <View style={styles.section}>
        <Text style={styles.title}>Report - {currentDate}</Text>
        <Text style={styles.text}>
          Note: You are looking at version 0.0.1 of the reports. All
          elements and sections of this report are subject to future changes
          and redesigns.
        </Text>
      </View>

      {/* General Section */}
      <View style={styles.section}>
        <Text style={styles.title}>General</Text>
        <Text style={styles.text}>Requested URL: {data.general.redirections.requestedUrl}</Text>
        <Text style={styles.text}>Final Mobile URL: {data.general.redirections.finalUrl.mobile}</Text>
        <Text style={styles.text}>Final Desktop URL: {data.general.redirections.finalUrl.desktop}</Text>
      </View>



      {/* Report Presentation Section */}
      <View style={styles.section}>
        <Text style={styles.title}>Report Presentation</Text>
        <Text style={styles.text}>
          {data.sectionsText["genral-introduction"].message.message.content}
        </Text>
      </View>

      

    {/* Overall app health Section */}
    <View style={styles.section}>
        <Text style={styles.title}>Overall app health</Text>
        <View>
          {/* Overall Score */}
          <View>
            <Text style={styles.text}>
              Overall score: {`${Math.floor(data.general.overviewAverageScore * 100)}%`} (
              {data.general.overviewAverageScore > 0.8
                ? "good"
                : data.general.overviewAverageScore > 0.5
                ? "medium"
                : "bad"}
              )
            </Text>
          </View>

          {/* Best Practices Score */}
          <View>
            <Text style={styles.text}>
              Best practices: {`${Math.floor(data.bestPractices.generalScore.average * 100)}%`} (
              {data.bestPractices.generalScore.average > 0.8
                ? "good"
                : data.bestPractices.generalScore.average > 0.5
                ? "medium"
                : "bad"}
              )
            </Text>
          </View>

          {/* Accessibility Score */}
          <View>
            <Text style={styles.text}>
              Accessibility: {`${Math.floor(data.usability.accessibility.score * 100)}%`} (
              {data.usability.accessibility.score > 0.8
                ? "good"
                : data.usability.accessibility.score > 0.5
                ? "medium"
                : "bad"}
              )
            </Text>
          </View>

          {/* Interactivity Score */}
          <View>
            <Text style={styles.text}>
              Interactivity: {`${Math.floor(data.usability.interactivity.speedIndex * 100)}%`} (
              {data.usability.interactivity.speedIndex > 0.8
                ? "good"
                : data.usability.interactivity.speedIndex > 0.5
                ? "medium"
                : "bad"}
              )
            </Text>
          </View>

          {/* Performance Score */}
          <View>
            <Text style={styles.text}>
              Performance: {`${Math.floor(data.technicalPerformance.generalScore.average * 100)}%`} (
              {data.technicalPerformance.generalScore.average > 0.8
                ? "good"
                : data.technicalPerformance.generalScore.average > 0.5
                ? "medium"
                : "bad"}
              )
            </Text>
          </View>
        </View>
      </View>

      {/* Screenshots Section */}
      <View style={styles.section}>
        <Text style={styles.title}>Desktop Screenshot</Text>

        {/* Desktop Screenshot */}
        {data.general.screenshots.desktop.length > 0 && (
          <Image
            style={styles.screenshot}
            src={data.general.screenshots.desktop}
          />
        )}
   <Text style={styles.title}>Mobile Screenshot</Text>
        {/* Mobile Screenshot */}
        {data.general.screenshots.mobile.length > 0 && (
          <Image
            style={styles.screenshot}
            src={data.general.screenshots.mobile}
          />
        )}
      </View>

            {/* Heuristic Evaluation Section */}
            <View style={styles.section}>
        <Text style={styles.title}>Heuristic Evaluation</Text>
        <Text style={styles.text}>
          {data.sectionsText["section-heuristic-evaluation"].message.message
            .content}
        </Text>
      </View>

        {/* Top recommendations for you Section */}
        <View style={styles.section}>
        <Text style={styles.title}>
        Heuristic evaluation recommendations for you
        </Text>
        <Text style={styles.text}>
          { data.sectionsText["section-heuristic-evaluation-top-recommendations"]
            .message.message.content}
        </Text>
      </View>

        {/* technical performance  Section */}
        <View style={styles.section}>
        <Text style={styles.title}>
          Technical Performance
        </Text>
        <Text style={styles.text}>
          { data.sectionsText["section-technical-perf-intro"].message.message
            .content}
        </Text>
      </View>

    

        {/* Top recommendations for you Section */}
        <View style={styles.section}>
        <Text style={styles.title}>
        Technical performance recommendations for you
        </Text>
        <Text style={styles.text}>
          { data.sectionsText["section-technical-performance-top-recommendations"]
            .message.message.content}
        </Text>
      </View>
    </Page>
  </Document>
);

export default ReportPDF;
