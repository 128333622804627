import { useState, useCallback } from "react";
import axios from "axios";
import styles from "./ForgetPassword.module.scss";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);

  const signIn = () => {
    navigate("/signin");
  };

  const forgotPasswordMutation = useMutation({
    mutationFn: () =>
      axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, {
        email,
      }),
    onError: (error) => {
      setError(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "Something went wrong"
      );
    },
    onSuccess: () => {
      setError(null);
      navigate("/reset-password/:token");
    },
  });

  const ForgetPassword = useCallback(() => {
    if (!email) {
      setError("Please fill in your email");
      return;
    }

    if (error) {
      setError(null);
    }

    forgotPasswordMutation.mutate();
  }, [email, error, forgotPasswordMutation]);

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.grayCard}`}></div> 
      <div className={`${styles.forgetPasswordCard}`}>
      <div className={`${styles.visosphere}`}>Visosphere.</div>
        <div className={`${styles.title}`}> Forgot Password </div>
        <div className={`${styles.sentence}`}> Enter your email address <br></br> and we will send you link to reset password</div>

        {error && <div className={`${styles.serverError}`}>{error}</div>}

        <Input
          label="Email"
          block
          type="email"
          value={email}
          onChange={setEmail}
        />

        <Button block type="primary" onClick={ForgetPassword}>
          Request reset link
        </Button>
        <div className={styles.signInLink}>
        Back To {' '}
          <span onClick={signIn} className={styles.signInLink}>
             Sign in
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
