import NoteProps from './props';
import styles from './note.module.scss'; 

const Note = ({ title, paragraph, style } : NoteProps) => {
  return (
    <div className={styles.noteContainer} style={style}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.paragraph}>{paragraph}</p>
    </div>
  );
};

export default Note;
