import React, { useState } from "react";
import { SelectProps } from "./props";
import CreatableSelect from "react-select/creatable";
import styles from "./creatable-select.module.scss";

interface Option {
  readonly label: string;
  readonly value: string;
}
const CreateSelect = ({
  label,
  defaultOptions,
  onCreateOption,
  onChange,
  className,
  placeholder,
  value,
  error,
  style,
  children,
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>(defaultOptions);

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = {
        label: inputValue,
        value: inputValue.toLowerCase().replace(/\W/g, ""),
      };
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      onCreateOption(inputValue);
    }, 1000);
  };

  return (
    <div
      style={style}
      className={`${styles.select}${className ? ` ${className}` : ""}`}
    >
      {label?.length ? <label className="label">{label}</label> : null}
      {children}
      <span className={`error${error?.length ? " active" : ""}`}>{error}</span>
      <CreatableSelect
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChange}
        onCreateOption={handleCreate}
        placeholder={placeholder}
        options={options}
        value={value}
      />
    </div>
  );
};
export default CreateSelect;
