import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CookiesProvider } from "react-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Login from "./Login";
import Signup from "./Signup";
import Audit from "./Audit";
import ConnectionRoute from "../Components/Router/ConnectionRoute";
import VerifyEmail from "./VerifyEmail";
import ResetPassword from "./ResetPassword";
import ForgetPassword from "./ForgetPassword";
import ReportManagement from "./ReportManagement";
import ProtectedRoute from "../Components/Router/ProtectedRoute";
import Home from "./Home";
import "./App.scss";
import Settings from "./Settings";
import AuditStart from "./AuditStart";
import UserManagement from "./UserManagement";
import ErrorPage from "./Error";
import SubscriptionPlan from "./SubscriptionPlan";
import Statistics from "./Statistics";
import Profile from "./Profil";
import { Helmet, HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<ConnectionRoute redirect="/audit" />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/audit" element={<AuditStart />} />
      <Route
        path="/settings"
        element={
          <ProtectedRoute redirectTo="/signin">
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route path="/audit/:id" element={<Audit />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify-email/:token" element={<VerifyEmail />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route
        path="/report"
        element={
          <ProtectedRoute redirectTo="/signin">
            <ReportManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/subscription-plan"
        element={
          <ProtectedRoute redirectTo="/signin">
            <SubscriptionPlan />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute redirectTo="/signin">
            <UserManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/statistics"
        element={
          <ProtectedRoute redirectTo="/signin">
            <Statistics />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute redirectTo="/signin">
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route path="/signin" element={<Login />} />
    </>
  )
);

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Visosphere Demo</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
      </Helmet>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID!}
      >
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <QueryClientProvider client={queryClient}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
            >
              <RouterProvider router={router} />
            </GoogleReCaptchaProvider>
          </QueryClientProvider>
        </CookiesProvider>
      </GoogleOAuthProvider>
    </HelmetProvider>
  );
};

export default App;
