import useWebSocket, { ReadyState } from "react-use-websocket";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import styles from "./Home.module.scss";
import prependURL, { testURLPrefix } from "../../utils/prependURL";

const Home = () => {
  const navigate = useNavigate();
  const [cookie] = useCookies(["tokens"]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_BASE_URL!.replace("http", "ws")}/reports?token=${
      cookie.tokens?.access?.token
    }`,
    {
      onOpen: () => {
        // Websocket opened
      },
      shouldReconnect: () => true,
    }
  );

  const [url, setUrl] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [currentDate, setCurrentDate] = useState("");

  const getCurrentDateTime = () => {
    const now = new Date();
    const months = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Août",
      "Sep",
      "Oct",
      "Nov",
      "Déc",
    ];
    const dayOfMonth = now.getDate().toString().padStart(2, "0");
    const month = months[now.getMonth()];
    const year = now.getFullYear();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${dayOfMonth} ${month} ${year} ; ${hours}:${minutes}`;
  };

  useEffect(() => {
    if (currentDate === "") {
      setCurrentDate(getCurrentDateTime());
    }
  }, [currentDate]);

  useEffect(() => {
    if (lastMessage !== null) {
      const parsed = JSON.parse(lastMessage.data);
      if (parsed.id) {
        navigate(`/audit/${parsed.id}`);
      }
    }
  }, [lastMessage, navigate]);

  useEffect(() => {
    if (!lastMessage || (lastMessage && !JSON.parse(lastMessage.data).id)) {
      setDisableButton(false);
    }
  }, [lastMessage]);

  const updateURL = useCallback((val: string) => {
    setUrl(val);
  }, []);

  const auditURL = useCallback(async () => {
    setDisableButton(true);
    let auditedURL = url;
    if (!testURLPrefix(url)) {
      auditedURL = prependURL(url);
      setUrl(auditedURL);
    }
    sendMessage(JSON.stringify({ urls: [auditedURL] }));
  }, [sendMessage, url]);

  const signUp = () => {
    navigate("/signup");
  };

  return (
    <div className={`${styles.containerWrapper}`}>
      <div className={`${styles.containerdiv}`}>
        <div className={`${styles.visosphere}`}>Visosphere.</div>
        <div className={`${styles.signupButton}`}>
          <Button
            type="primary"
            style={{ marginLeft: "0.5rem" }}
            onClick={signUp}
          >
            CREATE AN ACCOUNT
          </Button>
        </div>
      </div>
      <div className={`${styles.container}`}>
        <div className={`${styles.sentence}`}>
          {" "}
          Unlock the Untold Story of Your Website with Deeper Insights.
        </div>
        <div className={`${styles.searchbar}`}>
          <Input
            type="text"
            value={url}
            onChange={updateURL}
            style={{ width: "100%" }}
            block
            placeholder="Enter a web page URL"
            onEnter={auditURL}
          />
          <Button
            type="primary"
            style={{ marginLeft: "0.5rem" }}
            onClick={auditURL}
            disabled={disableButton || readyState !== ReadyState.OPEN}
          >
            ANALYZE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
