import { SelectProps } from "./props";
import styles from "./select.module.scss";
import Select from "react-select";

const BaseSelect = ({
  label,
  options,
  value,
  onChange,
  className,
  placeholder,
  disabled,
  isClearable,
  error,
  children,
  style,
}: SelectProps) => {
  return (
    <div
      style={style}
      className={`${styles.select}${className ? ` ${className}` : ""}`}
    >
      {label?.length ? <label className="label">{label}</label> : null}
      {children}
      <span className={`error${error?.length ? " active" : ""}`}>{error}</span>
      <Select
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        placeholder={placeholder}
        isClearable={isClearable}
        className={className ? `${styles.select} ${className}` : styles.select}
    
  
      />
    </div>
  );
};

export default BaseSelect;
