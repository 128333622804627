import { useEffect, useState } from "react";
import styles from "./timer.module.scss";

const Timer = () => {
  const [startTime, setStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  useEffect(() => {
    // Start the timer when the websocket connection is open
    setStartTime(Date.now());
  }, []);

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (startTime) {
        const currentTime = Date.now();
        const elapsedTimeInSeconds = Math.floor(
          (currentTime - startTime) / 1000
        );
        setElapsedTime(elapsedTimeInSeconds);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [startTime]);

  return (
    <div>
      <div className={`${styles.spinner}`}></div>
      <div className={`${styles.timer}`}>{formatTime(elapsedTime)}</div>
      <div className={`${styles.notifSentence}`}>
        {" "}
        Website Audit in progress. <br></br>
        You will be notified when it's ready.
      </div>

    </div>
  );
};

export default Timer;
