import BaseInputProps from "./props";
import styles from "./base-input.module.scss";

const BaseInput = ({
  label,
  children,
  className,
  error,
  style,
}: BaseInputProps) => {
  return (
    <div
      style={style}
      className={`${styles.baseInput}${className ? ` ${className}` : ""}`}
    >
      {label?.length ? <label className="label">{label}</label> : null}
      {children}
      <span className={`error${error?.length ? " active" : ""}`}>{error}</span>
    </div>
  );
};

export default BaseInput;
