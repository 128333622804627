import BaseInput from "../BaseInput";
import InputProps from "./props";
import styles from "./input.module.scss";

const defaultFunction = () => {};

const Input = ({
  label,
  className,
  block,
  type: inputType = "text",
  error,
  value,
  style,
  placeholder,
  icon,
  onChange = defaultFunction,
  onEnter = defaultFunction,
  onIconClick = defaultFunction,
  disabled,
}: InputProps) => {
  return (
    <BaseInput label={label} className={className} error={error} style={style}>
      <div className={styles.inputContainer}>
        <input
          value={value}
          type={inputType}
          placeholder={placeholder}
          className={`${styles.input}${error ? ` ${styles.error}` : ""}${
            block ? ` ${styles.block}` : ""
          }`}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter();
            }
          }}
          disabled={disabled}
        />
        {icon && (
          <div className={styles.icon} onClick={onIconClick}>
            {icon({})}
          </div>
        )}
      </div>
    </BaseInput>
  );
};

export default Input;
