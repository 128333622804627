import ModalProps from "./props";
import styles from "./modal.module.scss";
import Button from "../Button";


const defaultfunction = () => {};

const Modal = ({
  isOpen,
  onClose,
  children,
  style,
  onClick = defaultfunction,
  title,
  buttons,
  inputs = [],
}: ModalProps) => {
  return (
    <div
      className={`${styles.modal} ${isOpen ? styles.open : styles.closed}`}
      style={style } 
    >
      <div className={styles.content}>
        <div className={styles.visosphere}>
        {title}
        </div>
        <div className={styles.sentence}>
          <h4>Let's get started</h4>
        </div>
        <div className={styles.body}>
          {children}
         
          <div className={styles.inputGroup}>
              <div className={styles.inputContainer}>
                {inputs[0]}
              </div>
              <div className={styles.inputContainer}>
                {inputs[1]}
              </div>
            </div>

            <div className={styles.inputGroup}>
              <div className={styles.inputContainer}>
                {inputs[2]}
              </div>
              <div className={styles.inputContainer}>
                {inputs[3]}
              </div>
            </div>

          {buttons && (
            <div className={styles.roleSelection}>
         
              <div className={styles.roleHeader}>Your Role:</div>
              <div className={styles.roleOptions}>
                {buttons.map((button, index) => (
                  <div className={styles.roleOption} key={index}>
                    {button}
                  </div>
                ))}
              </div>
            </div>
          )}
          <Button className={styles.startButton} onClick={onClick}>
            START
          </Button>
          <Button className={styles.closeButton} onClick={onClose}>
            SKIP
          </Button>
      
        </div>
      </div>
    </div>
  );
};

export default Modal;
