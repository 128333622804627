import ColorsRecapProps from "./props";
import styles from "./colors-recap.module.scss";

const ColorsRecap = ({ colors = [] }: ColorsRecapProps) => {
  return (
    <div className={`${styles.colorsRecap}`}>
      {colors.map((color) => (
        <div className={`${styles.colorWrapper}`}>
          <div
            className={`${styles.color}`}
            style={{ backgroundColor: `${color}` }}
          />
        </div>
      ))}
    </div>
  );
};

export default ColorsRecap;
