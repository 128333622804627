import React from 'react';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

type ProtectedRouteProps = {
  children: React.ReactNode;
  redirectTo: string;
};

const ProtectedRoute = ({ children, redirectTo } :ProtectedRouteProps ) => {
  const [cookies] = useCookies(['tokens']);
  const isAuthenticated = !!cookies.tokens;

  return isAuthenticated ? <>{children}</> : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
