import ReportCardsGroupProps from "./props";
import styles from "./reportCardsGroup.module.scss";

const ReportCardsGroup = ({
  children,
  title,
  description,
}: ReportCardsGroupProps) => {
  return (
    <div className={styles.reportCardsGroup}>
      {title?.length ? <div className={styles.title}>{title}</div> : null}
      {description?.length ? (
        <div className={styles.description}>{description}</div>
      ) : null}
      {children ? <div className={styles.cardsWrapper}>{children}</div> : null}
    </div>
  );
};

export default ReportCardsGroup;
