import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ReportManagement.module.scss";
import Button from "../../Components/UI/Button";
import { fetchReports } from "../APICalls/report/index";
import dayjs from "../../utils/dayjs";
import Input from "../../Components/UI/Input";
import Navbar from "../../Components/Navbar";
import { useQuery } from "@tanstack/react-query";
import { RiEyeLine } from "react-icons/ri";

function ReportList() {
  const navigate = useNavigate();
  const [cookie] = useCookies(["tokens", "user"]);
  const [searchTerm, setSearchTerm] = useState<string>(""); // Input for search term
  const [filteredReports, setFilteredReports] = useState<any[]>([]); // State to store filtered reports
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { data: reports = [] } = useQuery({
    queryKey: ["reports", cookie.tokens?.access?.token, cookie.user.id],
    queryFn: () => fetchReports(cookie.tokens?.access?.token, cookie.user.id),
    enabled: !!cookie.tokens?.access?.token && !!cookie.user.id,
  });

  // Set filtered reports to all reports initially
  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      // If search term is empty, display all reports
      setFilteredReports(reports);
    } else {
      // Filter reports based on the search term
      const filtered = reports.filter((report: any) =>
        report.domain.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredReports(filtered);
    }
  };

  const handleViewReport = (report: any) => {
    if (report.status === "finished") {
      navigate(`/audit/${report.id}`);
    } else if (report.status === "stopped" || report.status === "failed") {
      setAlertMessage(`The report with status "${report.status}" cannot be viewed.`);
    }
  };

  return (
    <div>
      <Navbar />
      <div className={`${styles.containerWrapper}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.searchbar}`}>
            <Input
              value={searchTerm}
              onChange={(value: string) => setSearchTerm(value)} // Input field updates search term
              placeholder="Search by domain"
            />
            <Button block type="primary" onClick={handleSearch}>
              SEARCH
            </Button>
          </div>

          {alertMessage && (
            <div className={styles.alertMessage}>
              {alertMessage}
              <button onClick={() => setAlertMessage(null)}>Dismiss</button>
            </div>
          )}

          {filteredReports.length > 0 ? (
            <table className={`${styles.reportTable}`}>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Domain</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Overall Score</th>
                  <th>Crawled Links</th>
                  <th>Errors</th>
                  <th>Warnings</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredReports.map((report) => (
                  <tr key={report.id}>
                    <td>{report.title}</td>
                    <td>{report.domain}</td>
                    <td>
                      {dayjs(report.createdAt).format("DD MMMM YYYY hh:mm A")}
                    </td>
                    <td>{report.status}</td>
                    <td>-</td>
                    <td>1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      {report.status === "finished" ? (
                        <Link
                          to={`/audit/${report.id}`}
                          className={styles.viewLink}
                        >
                          <RiEyeLine />
                        </Link>
                      ) : (
                        <span
                          className={styles.viewLink}
                          onClick={() => handleViewReport(report)}
                        >
                          <RiEyeLine />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No reports found for the search term.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportList;
