import Note from "../Note";
import { ReportTabProps } from "./props";
import styles from "./reportTab.module.scss";

const ReportTab = ({ children }: ReportTabProps) => {
  return (
    <div className={styles.reportTab}>
      <Note
        title="Note:"
        paragraph="You are looking at version 0.0.1 of the reports. All elements and sections of this report are subjected to future changes and redesigns ."
      />
      {children}
    </div>
  );
};

export default ReportTab;
