// @ts-nocheck
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportPDF from "../ReportPDF";
import Button from "../Button";

interface DownloadPDFProps {
  data: any;
  currentDate: string;
  disabled?: boolean;
}

const DownloadPDF = ({ data, currentDate, disabled = false }: DownloadPDFProps) => {
  return (
    <PDFDownloadLink
      document={<ReportPDF data={data} currentDate={currentDate} />}
      fileName="report.pdf"
    >
      {({ loading }) =>
        loading ? (
          <Button type="secondary" disabled>
            Generating PDF...
          </Button>
        ) : (
          <Button type="secondary" disabled={disabled}>
            Download Report
          </Button>
        )
      }
    </PDFDownloadLink>
  );
};

export default DownloadPDF;
