import axios from "axios";

export const getStatistics = async (token: string) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/statistics`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export default getStatistics;
