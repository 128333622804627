import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import styles from "./navbar.module.scss";
import { IoNotifications } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import usePermission from "../../hooks/usePermission";

const Navbar: React.FC = () => {
  const [username, setUsername] = useState<string>("");

  const permission = usePermission();

  const location = useLocation();
  const [, , removeCookie] = useCookies(["user", "tokens"]);

  const handleLogout = () => {
    removeCookie("user");
    removeCookie("tokens");
    localStorage.clear();
  };

  const initialized = useRef<boolean | null>(null);

  useEffect(() => {
    if (!initialized?.current) {
      // prevent useless rerender
      initialized.current = true;
      const userDataFromCookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("user="))
        ?.split("=")[1];

      // Decode the URL encoded data
      const decodedData = userDataFromCookies
        ? decodeURIComponent(userDataFromCookies)
        : null;

      // Parse the JSON string to an object
      const userData = decodedData ? JSON.parse(decodedData) : null;

      // Extract the user's name and role
      const username = userData
        ? userData.first_name && userData.last_name
          ? `${userData.first_name} ${userData.last_name}`
          : userData.name || "User name"
        : "User name";

      setUsername(username); // Set username
    }
  }, []);

  return (
    <nav className={styles.navbar}>
      <div className={styles.navList}>
        <div className={styles.navItemBrand}>Visosphere.</div>
        <div className={styles.navCenterGroup}>
          {permission("admin") ? (
            <div
              className={`${styles.navItem} ${
                location.pathname === "/statistics" ? styles.active : ""
              }`}
            >
              <Link to="/statistics">Statistics</Link>
            </div>
          ) : null}
          {permission("user", true) ? (
            <div
              className={`${styles.navItem} ${
                location.pathname === "/audit" ? styles.active : ""
              }`}
            >
              <Link to="/audit">Website Audit</Link>
            </div>
          ) : null}
          {permission("user", true) ? (
            <div
              className={`${styles.navItem} ${
                location.pathname === "/report" ? styles.active : ""
              }`}
            >
              <Link to="/report">Reports</Link>
            </div>
          ) : null}
          {permission("admin") ? (
            <div
              className={`${styles.navItem} ${
                location.pathname === "/subscription-plan" ? styles.active : ""
              }`}
            >
              <Link to="/subscription-plan">Plans management</Link>
            </div>
          ) : null}
          {permission("admin") ? (
            <div
              className={`${styles.navItem} ${
                location.pathname === "/users" ? styles.active : ""
              }`}
            >
              <Link to="/users">Users List</Link>
            </div>
          ) : null}
          {permission("admin") ? (
            <div className={`${styles.navItem} ${styles.navItem}`}>
              <Link to="/error">Customer Support</Link>
            </div>
          ) : null}
          {permission("user") ? (
            <div
              className={`${styles.navItem} ${
                location.pathname === "/settings" ? styles.active : ""
              }`}
            >
              <Link to="/settings">Settings</Link>
            </div>
          ) : null}
        </div>
        <div className={styles.navRightGroup}>
          <div className={styles.navItemIcon}>
            <Link to="/notification">
              <IoNotifications size={24} />
            </Link>
          </div>
          {permission("user") && (
            <div className={styles.navItemButton}>
              <button>Talk to Us</button>
            </div>
          )}
          <div className={styles.navItemDropdown}>
            <div className={styles.dropdown}>
              <button className={styles.dropbtn}>
                {username}
                <RiArrowDropDownLine />
              </button>
              <div className={styles.dropdownContent}>
                <a href="/profile">Profile</a>
                <a href="/signin" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
