import React, { useCallback, useEffect, useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import Button from "../../Components/UI/Button";
import Input from "../../Components/UI/Input";
import Navbar from "../../Components/Navbar";
import { SelectOption } from "../../Components/UI/Select/props";
import styles from "./Settings.module.scss";
import {
  updateCurrentUser,
  getCurrentUser,
  deleteAccount,
} from "../APICalls/user";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie"; // Import useCookies
import Divider from "../../Components/UI/Divider";
import { useNavigate } from "react-router-dom"; // Correct import

type newUserData = {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  speciality?: SelectOption;
  company_name?: string;
  country?: string;
  company_size?: string;
  industry?: string;
  old_password?: string;
  password?: string;
  confirm_password?: string;
};

// user role options
const roleOptions: SelectOption[] = [
  { value: "product", label: "Product" },
  { value: "designer", label: "Designer" },
  { value: "engineer", label: "Engineer" },
  { value: "analyst", label: "Analyst" },
  { value: "marketing", label: "Marketing" },
  { value: "ux_researcher", label: "UX Researcher" },
  { value: "owner", label: "Owner" },
  { value: "other", label: "Other" },
];

const Settings: React.FC = () => {
  // get user cookies
  const [cookies, , removeCookie] = useCookies(["tokens", "user"]); // Destructure removeCookie
  const navigate = useNavigate();

  // form fields
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [role, setRole] = useState<SelectOption | undefined>();
  const [companyName, setCompanyName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [companySize, setCompanySize] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  // server errors
  const [errorMessage, setErrorMessage] = useState<string>("");

  const queryFn = useCallback(
    async () => await getCurrentUser(cookies.tokens.access.token),
    [cookies.tokens.access.token]
  );

  // get current user information
  const { data: currentUserData, error: queryError } = useQuery({
    queryKey: ["current-user"],
    queryFn,
  });

  const mutationFn = useCallback(async () => {
    const newUserData: newUserData = {
      first_name: firstName,
      last_name: lastName,
      email,
    };

    if (phoneNumber?.length) {
      newUserData.phone_number = phoneNumber;
    }

    if (role) {
      newUserData.speciality = role.value;
    }

    if (companyName?.length) {
      newUserData.company_name = companyName;
    }

    if (companySize?.length) {
      newUserData.company_size = companySize;
    }

    if (country?.length) {
      newUserData.country = country;
    }

    if (industry?.length) {
      newUserData.industry = industry;
    }

    if (
      oldPassword?.length &&
      password?.length &&
      confirmPassword?.length &&
      password === confirmPassword
    ) {
      newUserData.old_password = oldPassword;
      newUserData.password = password;
      newUserData.confirm_password = confirmPassword;
    }

    setErrorMessage("");

    return await updateCurrentUser(newUserData, cookies.tokens.access.token);
  }, [
    companyName,
    companySize,
    confirmPassword,
    cookies.tokens.access.token,
    country,
    email,
    firstName,
    industry,
    lastName,
    oldPassword,
    password,
    phoneNumber,
    role,
  ]);

  const { mutate, error: mutationError } = useMutation({
    mutationKey: ["update-user"],
    mutationFn,
  });

  useEffect(() => {
    if (currentUserData) {
      setFirstName(currentUserData.first_name ?? "");
      setLastName(currentUserData.last_name ?? "");
      setEmail(currentUserData.email ?? "");
      setPhoneNumber(currentUserData.phone_number ?? "");
      setCompanyName(currentUserData.company_name ?? "");
      setCompanySize(currentUserData.company_size ?? "");
      setCountry(currentUserData.country ?? "");
      setIndustry(currentUserData.industry ?? "");
      setRole(
        roleOptions.find((el) => el.value === currentUserData.speciality) ??
          undefined
      );
    }
  }, [currentUserData]);

  const togglePasswordVisibility = (): void => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (queryError) {
      setErrorMessage(queryError.message);
    }
  }, [queryError]);

  useEffect(() => {
    if (mutationError) {
      setErrorMessage(mutationError.message);
    }
  }, [mutationError]);

  const toggleConfirmPasswordVisibility = (): void => {
    setShowConfirmPassword((prev) => !prev);
  };

  const toggleOldPasswordVisibility = (): void => {
    setShowOldPassword((prev) => !prev);
  };

  const { mutate: handleDeleteAccount } = useMutation<void, Error, void>({
    mutationFn: async () => {
      const token = cookies.tokens.access.token;

      await deleteAccount(token); // Pass the token directly here
    },
    onSuccess: () => {
      removeCookie("tokens");
      removeCookie("user");
      navigate("/");
    },
    onError: (error) => {
      if (error.message === "account not found") {
        setErrorMessage("Le compte a déjà été supprimé.");
      } else {
        setErrorMessage("Échec de la suppression du compte.");
      }
    },
  });

  return (
    <div>
      <Navbar />
      <div className={styles.settingsPage}>
        <div className={styles.settingsSectionLayout}>
        <div className={styles.textGroup}>
          <div className={styles.textGroupItem}>
            <h3>Contact person information:</h3>
            <p>
              This section contains the primary contact details of the user, including their first name, last name, email, and phone number. Keeping this information up to date ensures effective communication.
            </p>
          </div>
          <div className={styles.textGroupItemCompany}>
            <h3>Company information:</h3>
            <p>
              Here you can view  the company-related information, such as the company name, size, and industry. Accurate company details help provide tailored services and insights.
            </p>
          </div>
          <div className={styles.textGroupItemCompany}>
            <h3>Account information:</h3>
            <p>
              This section provides a summary of the user’s subscription status, including the subscription plan, activation date, and expiration date. Ensure your account details are correct for uninterrupted service.
            </p>
          </div>

          </div>
          <div className={styles.formContainer}>
            <div className={styles.formGroupContainer}>
              <div className={styles.formGroup}>
                <div className={styles.inputGroup}>
                  <Input
                    label="First Name"
                    block
                    type="text"
                    value={firstName}
                    onChange={(value: string) => setFirstName(value)}
                    placeholder="Enter your first name"
                  />
                </div>
                <div className={styles.inputGroup}>
                  <Input
                    label="Last Name"
                    block
                    type="text"
                    value={lastName}
                    onChange={(value: string) => setLastName(value)}
                    placeholder="Enter your last name"
                  />
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.inputGroup}>
                  <Input
                    label="Email"
                    block
                    type="email"
                    value={email}
                    onChange={(value: string) => setEmail(value)}
                    placeholder="Enter your email"
                  />
                </div>
                <div className={styles.inputGroup}>
                  <Input
                    label="Phone Number"
                    block
                    type="text"
                    value={phoneNumber}
                    onChange={(value: string) => setPhoneNumber(value)}
                    placeholder="Enter your phone number"
                  />
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.inputGroup}>
                  <label className={styles.label}>Your Role:</label>
                  <div className={styles.roleOptions}>
                    {roleOptions.map((option) => (
                      <Button
                        key={option.value}
                        className={`${styles.roleOption} ${
                          role?.value === option.value ? styles.selected : ""
                        }`}
                        onClick={() => setRole(option)}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.inputGroup}>
                  <Input
                    label="Company Name"
                    block
                    type="text"
                    value={companyName}
                    onChange={(value: string) => setCompanyName(value)}
                    placeholder="Enter your company name"
                  />
                </div>
                <div className={styles.inputGroup}>
                  <Input
                    label="Company Size"
                    block
                    type="text"
                    value={companySize}
                    onChange={(value: string) => setCompanySize(value)}
                    placeholder="Enter your company size"
                  />
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.inputGroup}>
                  <Input
                    label="Country"
                    block
                    type="text"
                    value={country}
                    onChange={(value: string) => setCountry(value)}
                    placeholder="Enter your country"
                  />
                </div>
                <div className={styles.inputGroup}>
                  <Input
                    label="Industry"
                    block
                    type="text"
                    value={industry}
                    onChange={(value: string) => setIndustry(value)}
                    placeholder="Enter your industry"
                  />
                </div>
              </div>
              <div className={styles.passwordGroup}>
                <Input
                  label="Old Password"
                  block
                  type={showOldPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(value: string) => setOldPassword(value)}
                  placeholder="Enter your old password (at least 8 characters)"
                  icon={showOldPassword ? IoEyeOff : IoEye}
                  onIconClick={toggleOldPasswordVisibility}
                />
                <Input
                  label="Password"
                  block
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(value: string) => setPassword(value)}
                  placeholder="Enter your password (at least 8 characters)"
                  icon={showPassword ? IoEyeOff : IoEye}
                  onIconClick={togglePasswordVisibility}
                />
                <Input
                  label="Confirm Password"
                  block
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(value: string) => setConfirmPassword(value)}
                  placeholder="Enter your password again"
                  icon={showConfirmPassword ? IoEyeOff : IoEye}
                  onIconClick={toggleConfirmPasswordVisibility}
                />
              </div>
              {errorMessage && (
                <div className={styles.errorMessage}>{errorMessage}</div>
              )}
              <div className={styles.buttonContainer}>
                <Button type="primary" onClick={mutate}>
                  CONFIRM
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className={styles.deleteAccount}>
          <h3>Delete account:</h3>
          <Button
            type="danger"
            className={styles.deleteButton}
            onClick={() => handleDeleteAccount()}
          >
            Delete my account
          </Button>
        </div>
        <div className={styles.deleteAccountText}>
          <p>
            You previously agreed and consented to our Terms of Service. Last
            consent given on: July 22, 2024 8:14:14 AM. If you don't agree to
            these terms anymore, you can always{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              delete your account but all your data will be lost.
            </span>
            Once deleted you won't be able to go back and we'd be really sad to
            see you go.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Settings;
