import chroma from "chroma-js";
import ColorsDetails from "../ColorsDetails";
import ColorsRecap from "../ColorsRecap";
import ColorsTable from "../ColorsTable";
import Paragraph from "../Paragraph";
import ReportCardsGroup from "../ReportCardsGroup";
import ScoreCard from "../ScoreCard";
import { ReportSectionProps } from "./props";
import ReportTab from "./ReportTab";
import styles from "./reportContent.module.scss";
import { useMemo } from "react";
import FontPreview from "../FontPreview";
import Markdown from "react-markdown";
import AnalysisBox from "../AnalysisBox";
import { useCookies } from "react-cookie"; 
import { useNavigate } from "react-router-dom";


function onlyUnique(value: any, index: any, array: any) {
  return array.indexOf(value) === index;
}

const transformToSet = (
  colors: any = {},
  fgColors: any = [],
  bgColors: any = []
) => {
  const fg = fgColors.filter(onlyUnique);
  const bg = bgColors.filter(onlyUnique);
  const pairings: any = {};
  Object.keys(colors).forEach((color) => {
    pairings[color] = colors[color].pairings;
  });

  const colorSet: any[] = [];
  fg.forEach((color: string) => {
    const bgPairs = pairings[color]
      .filter((paired: string) => bg.includes(paired))
      .map((paired: string) => ({
        textColor: color,
        backgroundColor: paired,
        contrast: chroma.contrast(chroma(paired).hex(), chroma(color).hex()),
      }));

    colorSet.push(...bgPairs);
  });

  return colorSet;
};

const HeuristicEvaluation = ({ data }: ReportSectionProps) => {
  const navigate = useNavigate();
  const [cookie] = useCookies(["tokens", "user", "_id"]);
  const isConnected = !!cookie.tokens?.access?.token;
  const colorSet = useMemo(
    () =>
      transformToSet(
        data.design.colors.allDetailed,
        data.design.colors.fgColors,
        data.design.colors.bgColors
      ),
    [
      data.design.colors.allDetailed,
      data.design.colors.bgColors,
      data.design.colors.fgColors,
    ]
  );


  const signUp = () => {
    navigate("/signup");
  };
  
  return (
    <ReportTab>
       {isConnected ? (
        <>
      <Paragraph
        title="Overview"
        paragraph={
          data.sectionsText["section-heuristic-evaluation"].message.message
            .content
        }
      />
      <ReportCardsGroup
        title="Overall app health"
        description="View the individual factors that contributed to your health score"
      >
        <ScoreCard
          title="Accessibility"
          value={`${(data.usability.accessibility.score * 100).toFixed(2)}%`}
          status={
            data.usability.accessibility.score > 0.9
              ? "good"
              : data.usability.accessibility.score > 0.5
              ? "medium"
              : "bad"
          }
          cardStyle={2}
        />
        <ScoreCard
          title="Visibility of System Status"
          value="-"
          status="disabled"
          cardStyle={2}
        />
        <ScoreCard
          title="Error Prevention"
          value="-"
          status="disabled"
          cardStyle={2}
        />
        <ScoreCard
          title="Consistency and Standards"
          value="-"
          status="disabled"
          cardStyle={2}
        />
      </ReportCardsGroup>
      <h2>Color Analysis</h2>
      <ColorsRecap colors={Object.keys(data.design.colors.all)} />
      <h3>Paring list</h3>
      <ColorsDetails data={data.design.colors.allDetailed} />
      <ColorsTable colorSet={colorSet} />
      <h2>Font Analysis</h2>
      <h3>Key findings</h3>
      <Markdown>
        {data.sectionsText["fonts-findings-overview"].message.message.content}
      </Markdown>
      <h3>Evidence</h3>
      <Markdown>
        {data.sectionsText["fonts-findings-evidence"].message.message.content}
      </Markdown>
      <h3>fonts list</h3>
      {Object.keys(data.design.fonts).map((font) => (
        <FontPreview key={font} name={font} count={data.design.fonts[font]} />
      ))}
      <AnalysisBox
        title="Key Findings"
        description={
          data.sectionsText["section-heuristic-evaluation-key-findings"].message
            .message.content
        }
        question
        type="error"
      />
      <AnalysisBox
        title="Top recommendations for you"
        description={
          data.sectionsText["section-heuristic-evaluation-top-recommendations"]
            .message.message.content
        }
        question
        type="solution"
      />
      </>  ) : (
        <div className={`${styles.boxsignup}`}>
      
           <span onClick={signUp} className={styles.link}>
            Sign up
          </span>
          <p className={styles.p}>to access more detailed reports.</p> 
       </div>
      )}
    </ReportTab>
  );
};

export default HeuristicEvaluation;
