import { useState } from "react";
import { TabsProps } from "./props";
import styles from "./tabs.module.scss";

const Tabs = ({ tabs }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const handleTabClick = (key: string) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className={`${styles.tabs}`}>
        <ul className={`${styles.tabList}`}>
          {tabs.map((tab) => (
            <li
              key={tab.key}
              className={`${styles.tabItem} ${
                tab.key === activeTab ? styles.active : ""
              }`}
              style={tab.style}
              onClick={() => handleTabClick(tab.key)}
            >
              {tab.name}
            </li>
          ))}
        </ul>
        <div className={`${styles.tabContent}`}>
          {tabs.map((tab) => (
            <div
              key={tab.key}
              className={`${styles.tabPane} ${
                tab.key === activeTab ? styles.active : ""
              }`}
            >
              {Array.isArray(tab.content) ? (
                tab.content.map((item, id) => <div key={id}>{item}</div>)
              ) : (
                <div>{tab.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Tabs;
