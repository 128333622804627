import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

type rolesType = "user" | "admin" | "superAdmin";
type requiredRolesType = "connected" | "user" | "admin" | "superAdmin";

const roles: requiredRolesType[] = ["connected", "user", "admin", "superAdmin"];

const usePermission = () => {
  const [userCookie] = useCookies(["user"]);

  const [role, setRole] = useState<rolesType | undefined>();

  useEffect(() => {
    const userRole = userCookie?.user?.role;
    if (userRole && userRole !== role) {
      setRole(userRole);
    }
  }, [role, userCookie?.user?.role]);

  const callback = useCallback(
    (requiredRole: requiredRolesType = "connected", exact?: boolean) =>
      (!exact &&
        !!role &&
        roles.indexOf(role) >= roles.indexOf(requiredRole)) ||
      (exact && role === requiredRole),
    [role]
  );

  return callback;
};

export default usePermission;
