import { useCallback, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import {useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IoEye , IoEyeOff } from "react-icons/io5";
import Button from "../../Components/UI/Button";
import Input from "../../Components/UI/Input";
import styles from "./Login.module.scss";
import { Errors } from "./props";
import { useMutation } from "@tanstack/react-query";
import { login } from "../APICalls/user";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});
  const [, setCookie] = useCookies(["tokens", "user"]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const loginMutation = useMutation({
    mutationFn: async () => {
      if (!executeRecaptcha) {
        throw new Error("reCAPTCHA is not initialized");
      }
      const recaptchaToken = await executeRecaptcha("login");
      return login(email, password, recaptchaToken);
    },
    onError: (error) => {
      setErrors((old) => ({
        ...old,
        server: axios.isAxiosError(error)
          ? error?.response?.data.message
          : "something went wrong",
      }));
    },
    onSuccess: (data) => {
      if (data?.data?.tokens) {
        setCookie("tokens", JSON.stringify(data.data.tokens));
      }
      if (data?.data?.user) {
        setCookie("user", JSON.stringify(data.data.user));
        // Check user role and navigate accordingly
        if (data.data.user.role === "admin") {
          navigate("/statistics");
        } else {
          navigate("/audit");
        }
      }
    },
  });

 

  const logIn = useCallback(() => {
    let hasError = false;
    const newErrors: Errors = {};

    if (!email.trim()) {
      newErrors.email = "Please fill in your email";
      hasError = true;
    }

    if (!password.trim()) {
      newErrors.password = "Please fill in your password";
      hasError = true;
    }
    setErrors({});
    if (!hasError) {
      setErrors({});
      loginMutation.mutate();
    } else {
      setErrors((old) => ({ ...old, ...newErrors }));
    }
  }, [email, password, loginMutation]);


const togglePasswordVisibility = () => {
  setShowPassword((prev) => !prev);
};

const signUp = () => {
  navigate("/signup");
};
const loginGoogle = useGoogleLogin({
  onSuccess: async ({ code }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/google`, {
        code,
      });
      setCookie("tokens", JSON.stringify(response.data.tokens));
      setCookie("user", JSON.stringify(response.data.user));
      if (response?.data?.user) {
        setCookie("user", JSON.stringify(response.data.user));
        // Check user role and navigate accordingly
        if (response.data.user.role === "admin") {
          navigate("/statistics");
        } else {
          navigate("/audit");
        }
      }
    } catch (error) {
      console.error('Error logging in with Google:', error);
    }
  },
  flow: 'auth-code',
});
const Home = () => {
  navigate("/");
};
  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.grayCard}`}>   <img src="melio.png" alt="Error Illustration" width={700}  className={styles.roundedImage}/></div> 
      <div className={`${styles.loginCard}`}>
      <div className={styles.backContainer}>
      <div className={`${styles.visosphere}`} onClick={Home}>Visosphere.</div>
      <Link to="/" className={`${styles.backButton}`}>
        &rarr;
        </Link>
        </div> 

        <div className={`${styles.title}`}>Sign In</div>
        <div className={`${styles.sentence}`}> Let’s get you all set up so you can access your account</div>
        {errors.server ? (
          <div className={`${styles.serverError}`}>{errors.server}</div>
        ) : null}
        <Input
          label="Email"
          block
          type="email"
          onChange={setEmail}
          error={errors.email}
        />

       <Input
          label="Password"
          block
          type={showPassword ? "text" : "password"}
          onChange={setPassword}
          error={errors.password}
          icon={showPassword ? IoEyeOff : IoEye}
          onIconClick={togglePasswordVisibility}
         />

        <div className={`${styles.linkForgetPAssword}`}>
          <a href="/forget-password">Forgot your password?</a>
        </div>

        <Button block type="primary" onClick={logIn}>
        Sign in
        </Button>

        <div className={styles.linkSignUp}>
          You don't have an account? {' '}
          <span onClick={signUp} className={styles.linkSignUp}>
            Sign up
          </span>
        </div>
        <div className={styles.orDivider}>
          <div className={styles.dividerContainer}>
            <hr className={styles.dividerLine} />
            <span className={styles.orText}>Or </span>
            <hr className={styles.dividerLine} />
          </div>
        </div>

        <Button
          block
          type="google"
          onClick={loginGoogle}
        >
          <img src="googleicon.png" alt="google" />
          <span >  Connect with Google Account </span>
        </Button>
   
      
      </div>
    </div>
  );
};
export default Login ;

