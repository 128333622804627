import styles from "./ErrorPage.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/UI/Button";
const ErrorPage = () => {
  const navigate = useNavigate();
   const handleRefresh = () => {
        navigate("/");
    };
    const handleSupport = () => {
        navigate("/");
    };
return (
    <div className={`${styles.containerWrapper}`}>
    <div className={`${styles.sentence}`}>
      SOMETHING WENT WRONG ...
    </div>
    <div className={`${styles.buttonRow}`}>
        <Button type="secondary" onClick={handleRefresh} style={{ marginRight: "3rem" }}>
          Refresh
        </Button>
        <Button type="secondary" onClick={handleSupport}>
          Contact support
        </Button>
      </div>
      <div className={styles.imageContainer}>
        <img src="errorpage.png" alt="Error Illustration" />
      </div>

    </div>
  );
};

export default ErrorPage;