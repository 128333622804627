import useWebSocket, { ReadyState } from "react-use-websocket";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { updateUserById } from "../APICalls/user";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import styles from "./Audit.module.scss";
import prependURL, { testURLPrefix } from "../../utils/prependURL";
import Navbar from "../../Components/Navbar";
import UserModal from "../../Components/UI/UserModal";

const AuditStart = () => {
  const [cookie] = useCookies(["tokens", "user", "_id"]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_BASE_URL!.replace("http", "ws")}/reports?token=${
      cookie.tokens?.access?.token
    }`,
    {
      onOpen: () => {
        // Websocket opened
      },
      shouldReconnect: () => true,
    }
  );

  const [url, setUrl] = useState<string>(() => localStorage.getItem("auditURL") || "");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const isModalSkipped = localStorage.getItem("modalSkipped");
    const companyName = cookie.user?.company_name;

    if (!companyName && !isModalSkipped) {
      setShowModal(true);
    }
  }, [cookie.user?.company_name]);

  useEffect(() => {
    if (lastMessage !== null) {
      const parsed = JSON.parse(lastMessage.data);
      if (parsed.id) {
       
        navigate(`/audit/${parsed.id}`);
      }
    }
  }, [lastMessage, navigate]);

  useEffect(() => {
    if (!lastMessage || (lastMessage && !JSON.parse(lastMessage.data).id)) {
      setDisableButton(false);
    }
  }, [lastMessage]);


  const updateURL = useCallback((val: string) => {
    setUrl(val);
  }, []);

  const auditURL = useCallback(async () => {
    setDisableButton(true);

    // If the URL needs to be modified before audit
    let auditedURL = url;
    if (!testURLPrefix(url)) {
      auditedURL = prependURL(url);
      setUrl(auditedURL);
    }

     // Save the URL to localStorage
     localStorage.setItem("auditURL", auditedURL);


    // Proceed with sending the audit request
    sendMessage(JSON.stringify({ urls: [auditedURL] }));
  }, [sendMessage, url]);

  const handleModalSubmit = useCallback(
    async (userData: any) => {
      try {
        const userId = cookie.user?.id || null;
        const token = cookie.tokens?.access?.token;
        await updateUserById(userId, userData, token);
        setShowModal(false);
      } catch (error) {
        console.error("Error updating user information:", error);
      }
    },
    [cookie]
  );

  const handleModalSkip = () => {
    localStorage.setItem("modalSkipped", "true");
    setShowModal(false);
  };

  const isConnected = !!cookie.tokens?.access?.token;

  return (
    <div>
        {isConnected && <Navbar />}

      <div className={`${styles.containerWrapper}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.sentence}`}>
            {" "}
            Unlock the Untold Story of Your Website with Deeper Insights.
          </div>
          <div className={`${styles.searchbar}`}>
            <Input
              type="text"
              value={url}
              onChange={updateURL}
              style={{ width: "100%" }}
              block
              placeholder="Enter a web page URL"
              onEnter={auditURL}
            />
            <Button
              type="primary"
              style={{ marginLeft: "0.5rem" }}
              onClick={auditURL}
              disabled={disableButton || readyState !== ReadyState.OPEN}
            >
              ANALYZE
            </Button>
          </div>
        </div>
      </div>

      {isConnected && (
        <UserModal
          isOpen={showModal}
          onClose={handleModalSkip}
          onSubmit={handleModalSubmit}
        />
      )}
    </div>
  );
};

export default AuditStart;
