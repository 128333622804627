import { RiInformation2Fill } from "react-icons/ri";
import ScoreCardProps from "./props";
import styles from "./scoreCard.module.scss";

const ScoreCard = ({
  title = "-",
  value = "0",
  status = "good",
  description = "",
  cardStyle,
}: ScoreCardProps) => {
  return (
    <div
      className={`${styles.scoreCard} ${
        status === "good"
          ? styles.good
          : status === "medium"
          ? styles.medium
          : status === "bad"
          ? styles.bad
          : styles.disbaled
      } `}
    >
      <div
        className={`${styles.topSection}${
          cardStyle === 2 ? ` ${styles.styleTwo}` : ""
        }`}
      >
        <div className={cardStyle === 2 ? styles.smallTitle : undefined}>
          {title}
        </div>
        {cardStyle === 2 ? (
          <div title={description}>
            <RiInformation2Fill />
          </div>
        ) : null}
      </div>
      <div
        className={`${styles.bottomSection}${
          cardStyle === 2 ? ` ${styles.styleTwo}` : ""
        }`}
      >
        <div className={styles.value}>{value}</div>
        <div className={styles.status}>
          {status === "good"
            ? "Good"
            : status === "medium"
            ? "Work to be done"
            : status === "bad"
            ? "Action needed"
            : "-"}
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
