import { useCookies } from "react-cookie";
import { ReportSectionProps } from "./props";
import ReportTab from "./ReportTab";
import styles from "./reportContent.module.scss";
import { useNavigate } from "react-router-dom";

const AnalyticsIntegration = ({ data }: ReportSectionProps) => {
  const [cookie] = useCookies(["tokens", "user", "_id"]);
  const isConnected = !!cookie.tokens?.access?.token;
  const navigate = useNavigate();

  const signUp = () => {
    navigate("/signup");
  };
  return (
    <ReportTab>
         {isConnected ? (
        <>
      <h2 style={{ fontSize: "3rem", textAlign: "center", margin: "3rem 0" }}>
        Coming soon...
      </h2>
      </>  ) : (
             <div className={`${styles.boxsignup}`}>
      
             <span onClick={signUp} className={styles.link}>
              Sign up
            </span>
            <p className={styles.p}>to access more detailed reports.</p> 
         </div>
      )}
    </ReportTab>
  );
};

export default AnalyticsIntegration;
