import FontPreviewProps from "./props";
import styles from "./font-preview.module.scss";
import Divider from "../Divider";
import { useCallback, useState } from "react";
import ArrowDown from "./ArrowDown";

const FontPreview = ({ name, count = 1, preview }: FontPreviewProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setOpen((current) => !current);
  }, []);

  return (
    <div className={`${styles.fontPreview}`}>
      <button
        type="button"
        className={`${styles.header}`}
        onClick={handleClick}
      >
        <div>
          <div className={`${styles.fontName}`}>{name}</div>
          <div>
            Used {count} time{count !== 1 ? "s" : ""}
          </div>
        </div>
        <ArrowDown
          className={`${styles.arrowIcon} ${open ? styles.rotate : ""}`}
        />
      </button>
      <div className={`${styles.body} ${open ? styles.open : ""}`}>
        <Divider />
        {!preview?.length ? (
          "Font preview tools are coming soon..."
        ) : (
          <img className={`${styles.preview}`} alt={name} src={preview} />
        )}
      </div>
    </div>
  );
};

export default FontPreview;
