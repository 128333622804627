import Card from "./Card";
import { SimpleCounterProps } from "./props";
import commonStyles from "./common.module.scss";

const Users = ({ number = 0 }: SimpleCounterProps) => {
  return (
    <Card>
      <div className={commonStyles.title}>Plans</div>
      <div className={commonStyles.value}>{number}</div>
    </Card>
  );
};

export default Users;
