import useWebSocket from "react-use-websocket";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { updateUserById } from "../APICalls/user";
import { stopReport } from "../APICalls/report";
import styles from "./Audit.module.scss";
import Navbar from "../../Components/Navbar";
import Timer from "../../Components/UI/Timer";
import UserModal from "../../Components/UI/UserModal";
import ReportContent from "../../Components/UI/ReportContent";
import Button from "../../Components/UI/Button";
import { saveReportHistory } from "../APICalls/report";
import dayjs from "dayjs";

type Response = {
  status?: string;
  step?: string;
  data?: {
    [key: string]: any;
  };
  reports?: {
    status?: string;
    data: {
      [key: string]: any;
    };
    url: string;
    createdAt: string;
    [key: string]: any;
  }[];
};

const Audit = () => {
  let { id } = useParams();

  const [cookie] = useCookies(["tokens", "user", "_id"]);
  const { sendMessage, lastMessage } = useWebSocket(
    `${process.env.REACT_APP_BASE_URL!.replace("http", "ws")}/reports?token=${
      cookie.tokens?.access?.token
    }`,
    {
      onOpen: () => {
        // Websocket opened
      },
      shouldReconnect: () => true,
    }
  );

  const [response, setResponse] = useState<Response>({});
  // console.log(response);

  const idSent = useRef(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    if (lastMessage !== null) {
      const parsedData = JSON.parse(lastMessage.data);
      setResponse(parsedData);

      if (parsedData.reports && parsedData.reports.length) {
        const reportCreatedAt = parsedData.reports[0].createdAt;
        setCurrentDate(dayjs(reportCreatedAt).format("DD MMMM YYYY HH:mm A"));
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    const isModalSkipped = localStorage.getItem("modalSkipped");
    const companyName = cookie.user?.company_name;

    if (!companyName && !isModalSkipped) {
      setShowModal(true);
    }
  }, [cookie.user?.company_name]);

  useEffect(() => {
    if (lastMessage !== null) {
      const parsedResponse = JSON.parse(lastMessage.data);
      setResponse(parsedResponse);

      if (parsedResponse.status === "error") {
        navigate("/error", {
          state: { message: parsedResponse.error || "An error occurred" },
        });
      }
    }
  }, [lastMessage, navigate]);

  const auditURL = useCallback(
    (restart?: boolean) => {
      sendMessage(JSON.stringify({ id, restart }));
      setResponse({});
      // temporaire
      // navigate(`/audit`);
    },
    [id, sendMessage]
  );

  useEffect(() => {
    if (!idSent.current && id?.length) {
      auditURL();
      idSent.current = true;
    }
  }, [auditURL, id?.length, idSent]);

  const restartAudit = useCallback(async () => {
   /* if (id) {
      try {
        const lastReport = response.reports?.[0];
        if (lastReport) {
          const reportHistory = {
            reportId: id,
            domain: lastReport.domain,
            title: lastReport.title,
            status: lastReport.status || "finished",
            type: lastReport.type || "single-page",
            version: lastReport.version,
            dataCollection: lastReport.dataCollection,
            userId: cookie.user._id,
          };

          await saveReportHistory(reportHistory, cookie.tokens.access.token);
        }
      } catch (error) {
        console.error("Error saving report history:", error);
      }
    }*/
    setResponse({});
    setCurrentDate(dayjs().format("DD MMMM YYYY HH:mm"));
    navigate(`/audit`);
  }, [navigate]);



  const handleModalSubmit = useCallback(
    async (userData: any) => {
      try {
        const userId = cookie.user?.id || null;
        const token = cookie.tokens?.access?.token;
        await updateUserById(userId, userData, token);
        setShowModal(false);
      } catch (error) {
        console.error("Error updating user information:", error);
      }
    },
    [cookie]
  );

  const handleModalSkip = () => {
    localStorage.setItem("modalSkipped", "true");
    setShowModal(false);
  };

  const isConnected = !!cookie.tokens?.access?.token;

  const handleStopReport = async () => {
    if (id) {
      try {
        await stopReport(id, cookie.tokens?.access?.token);
        setResponse({});
        navigate("/report");
      } catch (error) {
        console.error("Error stopping the report:", error);
      }
    } else {
      console.error("Report ID not found");
    }
  };

  return (
    <>
      <div>
        {isConnected && <Navbar />}

        {!(response.status === "finished" && !!response.reports?.length) ? (
          <div className={`${styles.containerWrapper}`}>
            <div className={`${styles.container}`}>
            {response.status !== "finished" ? <Timer /> : null}
              <div className={`${styles.stopcontainer}`}>
                <Button
                  type="stop"
                  style={{ marginLeft: "0.5rem" }}
                  onClick={handleStopReport}
                >
                  STOP
                </Button>
              </div>
              </div>
            </div>

        ) : null}

        <div className={`${styles.containerWrapper}`}>
          <div className={`${styles.container}`}>
         

            {/* use the reportContent component */}
            {response.status === "finished" && !!response.reports?.length ? (
              <ReportContent
                data={response.reports[0].data}
                currentDate={currentDate}
                restartAudit={restartAudit}
              />
            ) : null}
          </div>
        </div>

        {isConnected && (
          <UserModal
            isOpen={showModal}
            onClose={handleModalSkip}
            onSubmit={handleModalSubmit}
          />
        )}
      </div>
    </>
  );
};

export default Audit;
