import axios from "axios";

interface Plan {
  id: string;
  name: string;
  monthly_rate: number;
  yearly_rate?: number;
  currency: string;
  active: boolean;
  monthly_tokens: number;
  monthly_websites: number;
  urls_per_website: number;
  report_tier: number;
  country_codes: string[];
  characteristics: Characteristic[];
}

interface Characteristic {
  category: string;
  content: string;
}

interface PlanDetailsCategory {
  id: string;
  name: string;
}

export const fetchSubscriptionPlans = async (token: string) => {
  try {
    const response = await axios.get<Plan[]>(
      `${process.env.REACT_APP_BASE_URL}/subscriptionPlans`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription plans:", error);
    throw new Error("An error occurred while fetching subscription plans.");
  }
};

export const fetchPlanDetailsCategories = async (token: string) => {
  try {
    const response = await axios.get<PlanDetailsCategory[]>(
      `${process.env.REACT_APP_BASE_URL}/planDetailsCategories`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching plan details categories:", error);
    throw new Error(
      "An error occurred while fetching plan details categories."
    );
  }
};
