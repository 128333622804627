import { useQuery } from "@tanstack/react-query";
import Navbar from "../../Components/Navbar";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import getStatistics from "../APICalls/statistics";
import styles from "./Statistics.module.scss";
import Users from "../../Components/UI/Statistics/Users";
import Plans from "../../Components/UI/Statistics/Plans";
import ActivePlans from "../../Components/UI/Statistics/ActivePlans";
import Reports from "../../Components/UI/Statistics/Reports";
import ReportsDistribution from "../../Components/UI/Statistics/ReportsDistribution";

const Statistics = () => {
  const [cookies] = useCookies(["tokens", "user"]);
  const queryFn = useCallback(
    async () => await getStatistics(cookies.tokens.access.token),
    [cookies.tokens.access.token]
  );
  const { data } = useQuery({
    queryKey: ["statistics"],
    queryFn,
    refetchOnWindowFocus: false,
  });
  // console.log(data);

  return data ? (
    <>
      <Navbar />
      <div className={styles.statistics}>
        <Users number={data.users} />
        <Plans number={data.plans} />
        <ActivePlans number={data.activePlans} />
        <Reports number={data.reports} />
        <ReportsDistribution data={data.reportsByStatus} />
      </div>
    </>
  ) : null;
};

export default Statistics;
