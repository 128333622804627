import axios from "axios";

interface Report {
  id: number;
  domain: string;
  createdAt: string;
  title: string;
  overallScore: number;
  crawledLinks: number;
  errors: number;
  warnings: number;
  status: string;
}

interface ReportHistory {
  reportId: string;
  domain: string;
  title: string;
  status: string;
  type: string;
  version: string;
  dataCollection: string[];
  userId: string;
}

export const saveReportHistory = async (reportHistory: ReportHistory, token: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/reportHistories`,
      reportHistory,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving report history:", error);
    throw new Error("An error occurred while saving the report history.");
  }
};


export const fetchReports = async (token: string, userId: number) => {
  try {
    const response = await axios.get<Report[]>(
      `${process.env.REACT_APP_BASE_URL}/reports/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching reports:", error);
    throw new Error("An error occurred while fetching reports.");
  }
};

export const handleDeleteReport = async (reportId: number, token: string) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/reports/${reportId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // return await fetchReports(token , userId );
  } catch (error) {
    console.error("Error deleting report:", error);
    throw new Error("An error occurred while deleting the report.");
  }
};

export const stopReport = async (reportId: string, token: string) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/reports/${reportId}/stop`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error stopping report:", error);
    throw new Error("An error occurred while stopping the report.");
  }
};
