import axios from "axios";

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  speciality?: string;
  country?: string;
  industry?: string;
  company_name?: string;
  company_size?: string;
  type?: string;
  subscriptionStatus?: string;
}
export const login = (
  email: string,

  password: string,

  recaptchaToken: string
) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
    data: {
      email,
      password,
      recaptchaToken,
    },
  });
};

export const signup = (
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  phone_number: string,
  recaptchaToken: string
) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/auth/register`,
    data: {
      first_name,
      last_name,
      email,
      password,
      phone_number,
      recaptchaToken,
    },
  });
};

export const updateUserById = (
  userId: string,
  userData: any,
  token: string
) => {
  return axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_BASE_URL}/users/${userId}`,
    data: userData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCurrentUser = async (userData: any, token: string) => {
  const response = await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_BASE_URL}/users/current-user`,
    data: userData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getCurrentUser = async (token: string) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/users/current-user`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const deleteAccount = async (token: string): Promise<void> => {
  const currentUser = await getCurrentUser(token);
  if (currentUser.deleted_at) {
    throw new Error("account not found");
  }
  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_BASE_URL}/users/current-user`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return;
};

export const fetchUsers = async (token: string) => {
  try {
    const response = await axios.get<User[]>(
      `${process.env.REACT_APP_BASE_URL}/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("An error occurred while fetching users.");
  }
};

export const deleteUserById = async (id: number, token: string) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Error deleting user:", error);
    throw new Error("An error occurred while deleting the user.");
  }
};

export const fetchSubscriptionForUser = async (
  userId: number,
  token: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/userSubscriptions/user/${userId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const subscriptionData = await response.json();
    return subscriptionData;
  } catch (error) {
    console.error(`Error fetching subscription for user ${userId}:`, error);
    return null;
  }
};
