import Card from "./Card";
import { ReportsDistributionProps } from "./props";
import commonStyles from "./common.module.scss";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = ["#C4D6B0", "#477998", "#291F1E", "#F64740", "#A3333D"];

const Reports = ({ data = [] }: ReportsDistributionProps) => {
  console.log(data);

  return (
    <Card>
      <div className={commonStyles.title}>Reports by status</div>
      <div style={{ height: "300px", width: "300px" }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie data={data} dataKey="count" nameKey="status" label>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default Reports;
