import Markdown from "react-markdown";
import AnalysisBoxProps from "./props";
import styles from "./analysisBox.module.scss";

const AnalysisBox = ({
  title,
  description,
  type = "neutral",
  question,
}: AnalysisBoxProps) => {
  return (
    <div
      className={`${styles.analysisBox} ${
        type === "error"
          ? styles.error
          : type === "solution"
          ? styles.solution
          : ""
      }`}
    >
      {title?.length ? <div className={styles.title}>{title}</div> : null}
      {description?.length ? <Markdown>{description}</Markdown> : null}
      {question ? (
        <div className={styles.question}>
          Was this card helpful? <span>Yes</span> <span>No</span>
        </div>
      ) : null}
    </div>
  );
};

export default AnalysisBox;
