import chroma from "chroma-js";
import styles from "./colorsTable.module.scss";
import ColorsTableProps from "./props";

const ColorsTable = ({ colorSet = [] }: ColorsTableProps) => {
  return (
    <table className={styles.colorsTable}>
      <thead>
        <tr>
          <th>Pairing</th>
          <th>Background Color Name & Hex Code</th>
          <th>Text Color Name & Hex Code</th>
          <th>Contrast Ratio</th>
          <th>WCAG Compliance (AA)</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        {colorSet.length === 0 ? (
          <tr>
            <td colSpan={6} style={{ textAlign: "center", padding: "2rem" }}>
              No colors
            </td>
          </tr>
        ) : (
          colorSet.map((set, index) => {
            const fg = chroma(set.textColor).hex();
            const bg = chroma(set.backgroundColor).hex();
            const contrast = chroma.contrast(fg, bg);
            return (
              <tr key={`${set.textColor}-${index}`}>
                <td>{index}</td>
                <td>
                  <div className={styles.colorInfo}>
                    <span
                      className={styles.colorPreview}
                      style={{ backgroundColor: set.textColor }}
                    />{" "}
                    {fg}
                  </div>
                </td>
                <td>
                  <div className={styles.colorInfo}>
                    <span
                      className={styles.colorPreview}
                      style={{ backgroundColor: set.backgroundColor }}
                    />{" "}
                    {bg}
                  </div>
                </td>
                <td>{contrast.toFixed(2)}</td>
                <td>
                  {contrast < 4.5 ? (
                    <span className={styles.contrastFail}>Fail</span>
                  ) : (
                    <span className={styles.contrastPass}>Pass</span>
                  )}
                </td>
                <td>
                  {contrast < 4.5
                    ? "Text is difficult to read against the background"
                    : `High contrast,
                    excellent readability`}
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default ColorsTable;
