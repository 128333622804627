import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "./VerifyEmail.module.scss";

const VerifyEmail = () => {
  const { token } = useParams(); // Correctly extract token from URL
  const [verificationStatus, setVerificationStatus] = useState("");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Send POST request with token in request body
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/verify-email?token=${token}`
        );

        if (response.status === 204) {
          setVerificationStatus("Email verified successfully!");
        } else {
          setVerificationStatus("Verification failed.");
        }
      } catch (error) {
        console.error(error);
        setVerificationStatus("An error occurred. Please try again later.");
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.verifyEmailCard}`}>
        <div className={`${styles.title}`}>Email Verification ...</div>
        <p>{verificationStatus}</p>
        {}
      </div>
    </div>
  );
};

export default VerifyEmail;
